import React, { useState } from 'react';
import { Storage } from '@google-cloud/storage';
import { useEffect } from 'react';
import { serverConfig } from 'firebase/server.config';
import FileUpload from 'utilities/FileUpload/FileUpload';

const storage = new Storage({
  projectId: 'servito-homdom-server-main',
  keyFilename: serverConfig,
});

const UploadFile = () => {
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  useEffect(() => {
    console.log(selectedFile)
  }, [selectedFile])
  

  const handleUpload = async () => {
    if (!selectedFile) return;
    console.log({selectedFile})
    // const bucketName = 'servito_public';
    // const filename = selectedFile.name;
    // console.log( {bucketName, filename})
    // const bucket = storage.bucket(bucketName);
    // await bucket.upload(selectedFile, {destination: filename});

    // console.log(`File "${filename}" uploaded successfully.`);
    const files = [selectedFile]
    const result = await FileUpload(`/uploads/`, files)
    console.log({result})
  };

  return (
    <div>
      <input type="file" onChange={handleFileChange} />
      <button onClick={handleUpload}>Upload</button>
    </div>
  );
};

export default UploadFile;