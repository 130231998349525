import { Button, FormControlLabel, FormGroup, FormLabel, Radio, RadioGroup, TextField, Typography, Tooltip, IconButton } from '@mui/material'
import { serverUrl } from 'Constants'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@material-ui/icons/Remove';

const mapState = ({ user }) => ({
    currentUser: user.currentUser,
})

function EditConsultationPlan({ plan, postUpdate, setLoading, setError }) {
    const { currentUser } = useSelector(mapState)
    const [title, setTitle] = useState('')
    const [subtitle, setSubtitle] = useState('')
    const [price, setPrice] = useState('')
    const [readyToSale, setReadyToSale] = useState(null);
    const [isRecommended, setIsRecommended] = useState(null);
    const [features, setFeatures] = useState([]);
    const [newFeature, setNewFeature] = useState('');
    const [expiry, setExpiry] = useState('');

    useEffect(() => {
        if (plan) {
            setTitle(plan.title)
            setSubtitle(plan.subtitle)
            setPrice(plan.price)
            setReadyToSale(plan.readyToSale)
            setIsRecommended(plan.isRecommended)
            setFeatures(plan.features)
            setExpiry(plan.expiry)
        } else reset()
    }, [plan])

    const handleFeatureChange = (index, value) => {
        const updatedFeatures = [...features];
        updatedFeatures[index] = value;
        setFeatures(updatedFeatures);
    };

    const handleRemoveFeature = (index) => {
        const updatedFeatures = [...features];
        updatedFeatures.splice(index, 1);
        setFeatures(updatedFeatures);
    };

    const addFeature = () => {
        if (newFeature.trim() === '') {
            return;
        }
        setFeatures([...features, newFeature]);
        setNewFeature('');
    };


    const handleSave = () => {
        setLoading(true)
        if (plan) {
            console.log('Plan Edited')
            const config = {
                editBy: currentUser.email,
                operationType: 'edit',
                ...plan,
                title,
                subtitle,
                price,
                readyToSale,
                isRecommended,
                features,
                expiry
            }
            const options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ ...config }),
            };

            fetch(`${serverUrl}/v1/consultation/editConsultationPlan`, options)
                .then(response => response.json())
                .then(data => {
                    console.log('Response:', data)
                    if (data.error) {
                        setError(data.error)
                        setLoading(false)
                    } else {
                        postUpdate()
                        reset()
                    }
                })
                .catch(error => {
                    console.error('Error:', error)
                    setError(error.message)
                    setLoading(false)
                })
        } else {
            console.log('New Plan Created')
            const config = {
                editBy: currentUser.email,
                operationType: 'create',
                title,
                subtitle,
                price,
                readyToSale,
                isRecommended,
                features,
                expiry
            }
            const options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ ...config }),
            };

            fetch(`${serverUrl}/v1/consultation/editConsultationPlan`, options)
                .then(response => response.json())
                .then(data => {
                    console.log('Response:', data)
                    if (data.error) {
                        setError(data.error)
                        setLoading(false)
                    } else {
                        postUpdate()
                        reset()
                    }
                })
                .catch(error => {
                    console.error('Error:', error)
                    setError(error.message)
                    setLoading(false)
                })
        }
    }

    const reset = () => {
        setError('')
        setLoading(false)
        setTitle('')
        setSubtitle('')
        setPrice('')
        setReadyToSale(null)
        setIsRecommended(null)
        setFeatures([])
        setExpiry('')
    }

    return (
        <div>
            <Typography variant="h6" gutterBottom>{plan ? 'Edit Consultation Plan' : 'Create Consultation Plans'}</Typography>
            <TextField
                label="title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                sx={{ mt: 1, mb: 1 }}
            /> < br />
            <TextField
                label="sub Title"
                value={subtitle}
                onChange={(e) => setSubtitle(e.target.value)}
                sx={{ mt: 1, mb: 1 }}
            /> < br />
            <TextField
                label="price"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                sx={{ mt: 1, mb: 1 }}
            /> < br />
            <TextField
                label="expiry (Number Of Days)"
                value={expiry}
                onChange={(e) => setExpiry(e.target.value)}
                sx={{ mt: 1, mb: 1 }}
            /> < br />
            <FormGroup>
                <FormLabel>Is Ready To Sale</FormLabel>
                <RadioGroup
                    value={readyToSale}
                    onChange={(e) => setReadyToSale(e.target.value)}
                    row
                >
                    <FormControlLabel
                        value="true"
                        control={<Radio />}
                        label="True" />
                    <FormControlLabel
                        value="false"
                        control={<Radio />}
                        label="False" />
                </RadioGroup>
            </FormGroup>
            <FormGroup>
                <FormLabel>Is Recommended</FormLabel>
                <RadioGroup
                    value={isRecommended}
                    onChange={(e) => setIsRecommended(e.target.value)}
                    row
                >
                    <FormControlLabel
                        value="true"
                        control={<Radio />}
                        label="True" />
                    <FormControlLabel
                        value="false"
                        control={<Radio />}
                        label="False" />
                </RadioGroup>
            </FormGroup>
            {features.map((feature, index) => (
                <div key={index} style={{ marginBottom: '16px' }}>
                    <TextField
                        label={`Feature ${index + 1}`}
                        value={feature}
                        onChange={(e) => handleFeatureChange(index, e.target.value)}
                    />
                    <IconButton color="secondary" onClick={() => handleRemoveFeature(index)} style={{ marginLeft: '8px' }}>
                        <RemoveIcon />
                    </IconButton>
                </div>
            ))}
            <div style={{ marginBottom: '16px', display: 'flex', alignItems: 'center' }}>
                <TextField
                    label="Feature"
                    value={newFeature}
                    onChange={(e) => setNewFeature(e.target.value)}
                    onBlur={addFeature}
                />
                <IconButton
                    color="primary"
                    onClick={addFeature}
                    style={{ marginLeft: '8px' }}
                >
                    <AddIcon />
                </IconButton>
            </div>
            <Button
                variant='contained'
                onClick={() => handleSave()}
                disabled={plan ? false : false}>
                {plan ? 'Save Edit' : 'Create New'}
            </Button>
        </div>
    )
}

export default EditConsultationPlan
