import React, { useState } from 'react'
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { Cancel, DeleteSharp, Edit, OpenInNew } from '@mui/icons-material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import EditInsuranceBanner from '../EditInsuranceBanner';
import { useEffect } from 'react';
import { Chip, Grid, LinearProgress } from '@mui/material';
import { useSelector } from 'react-redux';
import { serverUrl } from 'Constants';

const mapState = ({ user }) => ({
    currentUser: user.currentUser,
})

function InsuranceBanners() {
    const { currentUser } = useSelector(mapState)
    const [banners, setBanners] = useState([])
    const [open, setOpen] = useState(false)
    const [action, setAction] = useState(null)
    const [edit, setEdit] = useState(false)
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setError('')
        fetchBanners()
    }, [])


    const fetchBanners = () => {
        setLoading(true)
        fetch(`${serverUrl}/v1/home/fetchInsuranceBanners`)
            .then(response => response.json())
            .then(data => {
                if (data.status === 'OK') {
                    setBanners(data.data.banners)
                    setLoading(false)
                } else {
                    setError(data.message)
                    console.error(data)
                    setLoading(false)
                }
            })
            .catch(error => {
                console.error('Error:', error);
                setError(error.message)
                setLoading(false)
            });
    }

    const handleDeleteConfimation = artwork => {
        setOpen(true)
        setAction(artwork)
        setError('')
    }

    const handleClose = () => {
        setOpen(false)
        setAction(null)
        setError('')
    }

    const handleDelete = () => {
        setOpen(false)
        setLoading(true)
        setAction(null)
        const config = {
            editBy: currentUser.email,
            operationType: 'delete',
            _id: action._id
        }

        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ ...config }),
        };

        fetch(`${serverUrl}/v1/home/editInsuranceBanner`, options)
            .then(response => response.json())
            .then(data => {
                setError('')
                console.log('Response:', data);
                setBanners(banners.filter(artwork => { return artwork._id !== action._id }))
            })
            .catch(error => {
                console.error('Error:', error);
                setError(error.message)
            })
        setLoading(false)
    }

    const handleEditView = banner => {
        setError('')
        setEdit(true)
        setAction(banner)
    }

    const removeEditMode = () => {
        setError('')
        setEdit(false)
        setAction(null)
    }

    const handlePostUpdate = () => {
        setError('')
        setEdit(false)
        setAction(null)
        setLoading(false)
        fetchBanners()
    }

    return (
        <>
            {loading ? <LinearProgress /> : null}
            <div>
                <Typography variant="h5" gutterBottom>Insurance Banners</Typography>
                {error !== '' ? <p>{error}</p> : null}
                <Grid container spacing={2} sx={{ mt: 2 }}>
                    <Grid item xs={6}>
                        {banners.map((banner, index) => {
                            const url = banner.imageUrl
                            return <div key={banner._id} id={banner._id}>
                                <Card sx={{ m: 1 }}>
                                    <CardMedia
                                        component="img"
                                        height="194"
                                        style={{width: 'auto', margin: '0 auto'}}
                                        image={url}
                                        key={`key-svg-${banner._id}`}
                                        id={`id-svg-${banner._id}`}
                                        alt={`banner ${index}`}
                                    />
                                    <CardContent>
                                        <Chip label={`For: ${banner.bannerFor}`} sx={{ m: 1 }} />
                                        <Chip label={`Type: ${banner.bannerType}`} sx={{ m: 1 }} />
                                        <Chip label={`Category: ${banner.bannerCategory}`} sx={{ m: 1 }} />
                                        <Chip label={`onPress: ${banner.onPress}`} sx={{ m: 1 }} />
                                    </CardContent>
                                    <CardActions disableSpacing>
                                        <IconButton aria-label="open image" onClick={() => window.open(url, '_blank')} title='open image'>
                                            <OpenInNew />
                                        </IconButton>
                                        <IconButton aria-label="delete" onClick={() => handleDeleteConfimation(banner)} title='delete'>
                                            <DeleteSharp />
                                        </IconButton>
                                        <IconButton aria-label="edit" onClick={() => edit && action._id === banner._id ? removeEditMode() : handleEditView(banner)} title='edit'>
                                            {edit && action._id === banner._id ? <Cancel /> : <Edit />}
                                        </IconButton>
                                    </CardActions>
                                </Card>
                            </div>
                        })}
                    </Grid>
                    <Grid item xs={6}>
                        {edit ? <EditInsuranceBanner
                            setError={setError}
                            banner={action}
                            postUpdate={handlePostUpdate}
                            setLoading={setLoading} /> :
                            <EditInsuranceBanner
                                setError={setError}
                                postUpdate={handlePostUpdate}
                                setLoading={setLoading} />}
                    </Grid>
                </Grid>
            </div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Are you sure you want to delete?"}
                </DialogTitle>
                <DialogContent>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={() => handleDelete()} autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default InsuranceBanners