import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setMainContentStart, signOutUserStart } from '../../../redux/user/user.actions';
import { useHistory } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import HomeIcon from '@material-ui/icons/Home';
import { makeStyles } from '@mui/styles';
import CategoryIcon from '@mui/icons-material/Category';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import { auth, getCurrentUser } from 'firebase/util';
import { ArtTrack, CategoryOutlined, PhotoAlbum, Tab, PhotoLibrary } from '@mui/icons-material';
import GroupIcon from '@mui/icons-material/Group';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import AddBoxIcon from '@mui/icons-material/AddBox';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import WebhookIcon from '@mui/icons-material/Webhook';

const drawerWidth = 260;
const appPackage = require('../../../../package.json')
const settings = ['Logout']

const mapState = ({ user }) => ({
  mainContent: user.mainContent,
  currentUser: user.currentUser
})

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    background: '#28559a'
  },
  titleTooltip: {
    zIndex: theme.zIndex.drawer + 2,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  drawer: {
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(4),
    [theme.breakpoints.up('sm')]: {
      marginLeft: drawerWidth,
    }
  },
  margin: {
    margin: '0 auto',
    width: "fit-content"
  },
}));

const Home = ({ mainContent }) => {
  const classes = useStyles();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [reservationMenu, setReservationMenu] = React.useState(null);
  const [navDrawSelection, setNavDrawSelection] = React.useState(null)
  const { currentUser } = useSelector(mapState)
  const [open, setOpen] = useState(false)
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    const unregisterAuthObserver = auth.onAuthStateChanged(user => {
      if (!user) dispatch(signOutUserStart())
    })
    return () => unregisterAuthObserver()
  }, [])

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  React.useEffect(() => {
    handleCloseNavMenu()
  }, [navDrawSelection])

  useEffect(() => {
    if (!currentUser) {
      history.push('/login')
    }
  }, [currentUser])

  const routes = [{
    name: 'Home',
    location: '/admin',
    icon: <HomeIcon />
  }, {
    name: 'App Strings',
    location: '/admin/strings',
    icon: <TextFieldsIcon />
  }, {
    name: 'Helper Category',
    location: '/admin/helper-category',
    icon: <CategoryIcon />
  }, {
    name: 'Helper Type',
    location: '/admin/helper-type',
    icon: <CategoryOutlined />
  }, {
    name: 'Toggles',
    location: '/admin/config-toggles',
    icon: <ToggleOnIcon />
  }, {
    name: 'Welcome Artworks',
    location: '/admin/welcome-artworks',
    icon: <ArtTrack />
  }, {
    name: 'Home Icons',
    location: '/admin/home-icons',
    icon: <AddBoxIcon />
  }, {
    name: 'Home Banners',
    location: '/admin/banners',
    icon: <PhotoAlbum />
  }, {
    name: 'Insurance Banners',
    location: '/admin/insurance-banner',
    icon: <PhotoLibrary />
  }, {
    name: 'Consultation Banners',
    location: '/admin/consultation-banner',
    icon: <AutoAwesomeMotionIcon />
  }, {
    name: 'Consultation Vouchers',
    location: '/admin/consultation-voucher',
    icon: <ConfirmationNumberIcon />
  }, {
    name: 'Consultation Plans',
    location: '/admin/consultation-plan',
    icon: <BorderColorIcon />
  }, {
    name: 'Subscriptions',
    location: '/admin/consultation-plan-subscribed',
    icon: <CardMembershipIcon />
  }, {
    name: 'Partner API Requests',
    location: '/admin/partner-api-requests',
    icon: <WebhookIcon />
  }, {
    name: 'Admin Users',
    location: '/admin/admin-users',
    icon: <GroupIcon />
  }]

  const handleRouteChange = route => {
    history.push(route)
    setOpen(false)
  }

  const drawer = (
    <div>
      <Toolbar />
      <Divider />
      <List>
        {routes.map((route, index) => (
          <ListItem key={index} disablePadding onClick={() => handleRouteChange(route.location)}>
            <ListItemButton>
              <ListItemIcon>
                {route.icon}
              </ListItemIcon>
              <ListItemText primary={route.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </div>
  );


  return (
    <>
      <AppBar position="fixed" sx={{ backgroundColor: '#000', zIndex: 1201 }}>
        <Toolbar disableGutters>
          <Box sx={{ display: { xs: 'block', md: 'none', sm: 'none' }, m: 1, ml: 2 }}>
            <MenuIcon onClick={() => setOpen(!open)} />
          </Box>
          <Box sx={{ display: { xs: 'none', md: 'block' }, height: { xs: 28, md: 32 }, m: 1, ml: 2 }} onClick={() => history.push('/admin')}>
            <Typography variant="h4" gutterBottom>{appPackage.displayName}</Typography>
          </Box>
          <Box sx={{
            m: 1,
            ml: 2,
            display: { xs: 'flex', md: 'none' },
            flexGrow: 1,
          }} onClick={() => setNavDrawSelection('home')}>
            <Typography textAlign="center" variant="h4" gutterBottom>{appPackage.displayName}</Typography>
          </Box>
          <Box style={{ flexGrow: 1 }}></Box>
          <Box sx={{ m: 1, mr: 2 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar>{currentUser ? currentUser.displayName ? currentUser.displayName.charAt(0) : 'HD' : 'HD'}</Avatar>
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={() => dispatch(signOutUserStart())}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
              {currentUser ? <MenuItem key={'email'} onClick={() => { }} disabled>
                <Typography textAlign="center">{currentUser.email}</Typography>
              </MenuItem> : null}
              <MenuItem key={'version'} onClick={() => { }} disabled>
                <Typography textAlign="center">Version {appPackage.version}</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', sm: 'block' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
        open
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="temporary"
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
        open={open}
      >
        {drawer}
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {mainContent}
      </main>
    </>
  );
};
export default Home;