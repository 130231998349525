import { serverUrl } from "Constants";
import { storage } from "../../firebase/util";

const FileUploadGCP = async (fileLocation, files) => {
    let fileUrls = []
    let status = 404
    let errors = []
    try {
        for (let i = 0; i < files.length; i++) {
            const response = await fetch(`${serverUrl}/v1/files/upload`, {
                method: 'post',
                body: files[i].formData,
            })
            const result = await response.json()
            console.log({ result, response, file: files[i] })
            fileUrls.push({ name: result.name, url: encodeURI(result.publicUrl) })
        }
        status = 200
    } catch (error) {
        console.error(error)
        errors.push(error.message)
    }
    return { fileUrls, status, errors }
}

export default FileUploadGCP
