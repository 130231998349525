import React, { useState } from 'react';
import { LinearProgress, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import UserTypeConfig from 'components/ChildComponents/UserTypeConfig';
import { useEffect } from 'react';
import { fetchConfigStart } from 'redux/user/user.actions';

const mapState = ({ user }) => ({
    config: user.config,
})

const ConfigToggles = () => {
    const { config } = useSelector(mapState)
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)

    useEffect(() => {
      dispatch(fetchConfigStart())
    }, [])
    

    return (
        <div>
            {loading ? <LinearProgress /> : null}
            <Grid container spacing={2} sx={{ mt: 2 }}>
                <Grid item xs={6}>
                    <UserTypeConfig
                        userType={'employer'}
                        setLoading={setLoading} />
                </Grid>
                <Grid item xs={6}>
                    <UserTypeConfig
                        userType={'helper'}
                        setLoading={setLoading} />
                </Grid>
            </Grid>
        </div>
    );
};

export default ConfigToggles;