import { Button, FormControlLabel, FormGroup, FormLabel, Radio, RadioGroup, TextField, Typography, Tooltip, Autocomplete } from '@mui/material'
import { serverUrl } from 'Constants'
import { HOME_ROUTES } from 'constants/OnPressRoutes'
import { DropzoneArea, DropzoneAreaBase } from 'material-ui-dropzone'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import FileUpload from 'utilities/FileUpload/FileUpload'
import FileUploadGCP from 'utilities/FileUploadGCP/FileUploadGCP'

const mapState = ({ user }) => ({
    currentUser: user.currentUser,
})

function EditHomeIcons({ icon, postUpdate, setLoading, setError }) {
    const { currentUser } = useSelector(mapState)
    const [image, setImage] = useState(null)
    const [selectedFile, setSelectedFile] = useState(null)
    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [isExpanded, setisExpanded] = useState(null)
    const [forAdmin, setforAdmin] = useState(null)
    const [iconFor, setIconFor] = useState(null)
    const [onPress, setOnPress] = useState('')
    const [uploading, setUploading] = useState(false)

    useEffect(() => {
        setImage(null)
        setSelectedFile(null)
    }, [])

    useEffect(() => {
        if (icon) {
            setName(icon.iconName)
            setDescription(icon.iconDescription)
            setisExpanded(icon.isExpanded)
            setforAdmin(icon.forAdmin)
            setIconFor(icon.iconFor)
            setOnPress(icon.onPress)
        } else reset()
    }, [icon])

    const handleSave = () => {
        setLoading(true)
        if (icon) {
            console.log('icon Edited')
            const config = {
                editBy: currentUser.email,
                operationType: 'edit',
                ...icon,
                iconName: name,
                iconDescription: description,
                isExpanded,
                forAdmin,
                iconFor,
                onPress
            }
            const options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ ...config }),
            };

            fetch(`${serverUrl}/v1/home/editIcons`, options)
                .then(response => response.json())
                .then(data => {
                    console.log('Response:', data)
                    if (data.error) {
                        setError(data.error)
                        setLoading(false)
                    } else {
                        postUpdate()
                        reset()
                    }
                })
                .catch(error => {
                    console.error('Error:', error)
                    setError(error.message)
                    setLoading(false)
                })
        } else {
            console.log('New icon Created')
            const config = {
                editBy: currentUser.email,
                operationType: 'create',
                iconUrl: image,
                iconName: name,
                iconDescription: description,
                isExpanded,
                forAdmin,
                iconFor,
                onPress,
                secondaryText: 'secondaryText',
                primaryText: 'primaryText'
            }
            const options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ ...config }),
            };

            fetch(`${serverUrl}/v1/home/editIcons`, options)
                .then(response => response.json())
                .then(data => {
                    console.log('Response:', data)
                    if (data.error) {
                        setError(data.error)
                        setLoading(false)
                    } else {
                        postUpdate()
                        reset()
                    }
                })
                .catch(error => {
                    console.error('Error:', error)
                    setError(error.message)
                    setLoading(false)
                })
        }
    }

    useEffect(() => {
        if (selectedFile) handleUpload()
    }, [selectedFile])

    const handleUpload = async () => {
        if (!selectedFile) return;
        setUploading(true)
        console.log({ selectedFile })
        let formData = new FormData();
        formData.append("file", selectedFile);
        const files = [{ ...selectedFile, formData }]
        const result = await FileUploadGCP(`${Date.now()}-${selectedFile.name}`, files)
        console.log({ result })
        if (result.status === 200) {
            setImage(result.fileUrls[0].url)
        }
        setUploading(false)
    };

    const reset = () => {
        document.getElementById('fileInput').value = ''
        setError('')
        setImage(null)
        setLoading(false)
        setSelectedFile(null)
        setName('')
        setDescription('')
        setisExpanded(null)
        setforAdmin(null)
        setIconFor('')
        setOnPress('')
    }


    return (
        <div>
            <Typography variant="h6" gutterBottom>{icon ? 'Edit Icons' : 'Create Icons'}</Typography>
            {!icon ? <div>
                <input type="file" id='fileInput' onChange={e => setSelectedFile(e.target.files[0])} accept=".svg,.png" /><br />
                {selectedFile && !image ? uploading ? 'Uploading file...' : <Button onClick={() => handleUpload()}>Upload</Button> : image ? 'Upload Successful' : null}
                <br />
            </div> : null}
            <TextField
                label="Icon Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                sx={{ mt: 1, mb: 1 }}
            /> < br />
            <FormGroup>
                <FormLabel>Is Expanded</FormLabel>
                <RadioGroup
                    value={isExpanded}
                    onChange={(e) => setisExpanded(e.target.value)}
                    row
                >
                    <FormControlLabel
                        value="true"
                        control={<Radio />}
                        label="True" />
                    <FormControlLabel
                        value="false"
                        control={<Radio />}
                        label="False" />
                </RadioGroup>
            </FormGroup>
            <FormGroup>
                <FormLabel>Icons For</FormLabel>
                <RadioGroup
                    value={iconFor}
                    onChange={(e) => setIconFor(e.target.value)}
                    row
                >
                    <FormControlLabel
                        value="helper"
                        control={<Radio />}
                        label="Helper" />
                    <FormControlLabel
                        value="employer"
                        control={<Radio />}
                        label="Employer" />
                </RadioGroup>
            </FormGroup>
            <FormGroup>
                <FormLabel>For Admin</FormLabel>
                <RadioGroup
                    value={forAdmin}
                    onChange={(e) => setforAdmin(e.target.value)}
                    row
                >
                    <FormControlLabel
                        value="true"
                        control={<Radio />}
                        label="True" />
                    <FormControlLabel
                        value="false"
                        control={<Radio />}
                        label="False" />
                </RadioGroup>
            </FormGroup>
            <TextField
                label="Icon Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                sx={{ mt: 1, mb: 1 }}
            /> < br />
            <Autocomplete
                disablePortal
                id="onpress-auto"
                options={HOME_ROUTES}
                getOptionLabel={option => option.title}
                onChange={(e, value) => setOnPress(value ? value.value : '')}
                sx={{ width: 200 }}
                renderInput={(params) => <TextField {...params} label="onPress" />}
                />
            <Tooltip title={icon ? '' : image ? '' : 'Upload the selected image to the cloud first'}>
                <Button
                    variant='contained'
                    onClick={() => handleSave()}
                    disabled={icon ? false : image ? false : true}>
                    {icon ? 'Save Edit' : 'Create New'}
                </Button>
            </Tooltip>
        </div>
    )
}

export default EditHomeIcons