import { Box, FormLabel, IconButton, InputAdornment, OutlinedInput, TextField } from '@mui/material';
import { firestore } from 'firebase/util';
import React , { useEffect, useState }from 'react'
import { useSelector } from 'react-redux';
import SaveIcon from '@mui/icons-material/Save';

const mapState = ({ user }) => ({
    config: user.config,
})

function BuildAndVersion({os, userType, setLoading}) {
    const { config } = useSelector(mapState)
    const [build, setBuild] = useState(17)
    const [version, setVersion] = useState('1.0.17')
    const versionPattern = /^1\.[0-9]\.[0-9]{2}$/
    const buildPattern = /^\d{2,3}$/

    useEffect(() => {
      try{
        setBuild(config[userType].build[os] || 17)
        setVersion(config[userType].version[os] || '1.0.17')
      }catch(error){
        console.error(error)
        setBuild(17)
        setVersion('1.0.17')
      }
    }, [config])
    

    const handleSave = async (field, value) => {
        try {
            setLoading(true)
            await firestore.collection('Config').doc(userType).set({[field]: {[os] : value}}, {merge: true});
            setLoading(false)
        } catch (error) {
            console.error('Error saving data:', error);
            setLoading(false)
        }
    };

  return (
    <div>
        <Box sx={{mt: 1, mb: 1}}>
            <FormLabel>{os.charAt(0).toUpperCase()+os.slice(1)}</FormLabel>
            <br />
            <OutlinedInput
                required
                value={version}
                id={`version-${os}`}
                label={`Version`}
                placeholder='1.0.17'
                onChange={e => setVersion(e.target.value)}
                sx={{mb: 1}}
                endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={e => handleSave('version', version)}
                        onMouseDown={e => e.preventDefault()}
                        edge="end"
                      >
                        {<SaveIcon />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
            <br />
            <OutlinedInput
                required
                value={build}
                id={`build-${os}`}
                label={`Build`}
                placeholder='17'
                type='number'
                onChange={e => setBuild(parseInt(e.target.value))}
                sx={{mb: 1}}
                endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={e => handleSave('build', parseInt(build))}
                        onMouseDown={e => e.preventDefault()}
                        edge="end"
                      >
                        {<SaveIcon />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
            </Box>
    </div>
  )
}

export default BuildAndVersion