import { createStore, applyMiddleware } from 'redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import createSagaMiddle from 'redux-saga';
import rootReducer from './rootReducer';
import rootSaga from './rootSaga';
import { persistStore } from 'redux-persist'

const sagaMiddleware = createSagaMiddle();
export const middlewares = process.env.NODE_ENV  === 'development' ? [logger, thunk, sagaMiddleware] : [thunk, sagaMiddleware];

export const store = createStore(rootReducer, applyMiddleware(...middlewares));
sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store)
const creatStoreExports = {store, persistor};
export default creatStoreExports;