import { Typography } from '@mui/material'
import UploadFile from 'components/ChildComponents/UploadFile'
import React from 'react'
import { useSelector } from 'react-redux'
import WelcomeArtworks from '../WelcomeArtworks'

const mapState = ({ user }) => ({
  currentUser: user.currentUser,
})

function NormalHome() {
  const { currentUser } = useSelector(mapState)

  return (
    <div>
      {currentUser ? <Typography variant="h5" gutterBottom>
        Hello, {currentUser.displayName}
      </Typography> : null}
    </div>
  )
}

export default NormalHome