import React, { useState, useEffect } from 'react';
import { firestore } from 'firebase/util';
import { List, ListItem, ListItemText, Button, TextField, Typography, IconButton, Box, ListItemAvatar, Avatar, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress, LinearProgress, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { fetchConfigStart } from 'redux/user/user.actions';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add'
import { select } from 'redux-saga/effects';


const mapState = ({ user }) => ({
  config: user.config,
})

const ArrayData = ({ name, field, isObjectSpecific=false, objectArray, objectName }) => {
  const { config } = useSelector(mapState)
  const dispatch = useDispatch()
  const [arrayValues, setArrayValues] = useState([]);
  const [newValue, setNewValue] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false)
  const [selectedObject, setSelectedObject] = useState('')

  useEffect(() => {
    dispatch(fetchConfigStart())
  }, []);

  useEffect(() => {
    try {
      if (config) {
        if (config[field]) {
          if(!isObjectSpecific) setArrayValues(config[field])
          else {
            if (config[field][selectedObject] && selectedObject !== '') setArrayValues(config[field][selectedObject])
            else setArrayValues([])
          }
        } else setArrayValues([])

        setLoading(config.loading)
      } else {
        setArrayValues([])
        setLoading(false)
      }
    } catch (error) {
      console.error(error)
      setArrayValues([])
      setLoading(false)
    }
  }, [config, selectedObject])


  const handleAddValue = () => {
    if (newValue.trim() !== '') {
      setIsDialogOpen(false)
      const updatedValues = [...arrayValues, newValue];
      updateFirestore(updatedValues);
      setNewValue('');
    }
  };

  const handleDeleteValue = (index) => {
    const updatedValues = arrayValues.filter((_, i) => i !== index);
    updateFirestore(updatedValues);
  };

  const handleDragEnd = (result) => {
    try {
      if (!result.destination) {
        setLoading(false)
        return;
      }
      const updatedValues = Array.from(arrayValues);
      const [removed] = updatedValues.splice(result.source.index, 1);
      updatedValues.splice(result.destination.index, 0, removed);
      updateFirestore(updatedValues);
    } catch (error) {
      console.error(error)
    }
  };

  const updateFirestore = async (data) => {
    try {
      setLoading(true)
      await firestore.collection('Config').doc('Uhb9yf38ykZEsAXAsNRo').set({ [`${field}`]: isObjectSpecific ? {[selectedObject] : [...data]} :[...data] }, { merge: true })
      setArrayValues([...data]);
      setLoading(false)
    } catch (error) {
      console.error('Error updating array values:', error);
      setLoading(false)
    }
  };

  return (
    <div>
      <Typography variant="h5" gutterBottom>
        {name}
        <IconButton
          aria-label="delete"
          onClick={() => setIsDialogOpen(true)}
          disabled={isObjectSpecific ? (selectedObject === '' || loading)  : loading}
          title='Add New Value'>
          <AddIcon />
        </IconButton>
      </Typography>
      <Box sx={{ width: '100%', maxWidth: '25vw' }}>
        {loading ? <LinearProgress /> : null}
        {isObjectSpecific ? <FormControl fullWidth>
          <InputLabel id="object-selector">{objectName}</InputLabel>
          <Select
            labelId="object-selector-label"
            id="object-selector-select"
            value={selectedObject}
            label="Object"
            onChange={e => setSelectedObject(e.target.value)}
          >
            <MenuItem value={''} disabled>Select A {objectName}</MenuItem>
            {objectArray.map((obj, index) => {
              return <MenuItem value={obj} key={index}>{obj}</MenuItem>
            })}
          </Select>
        </FormControl> : null}
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="arrayValues">
            {(provided) => (
              <List {...provided.droppableProps} ref={provided.innerRef}>
                {arrayValues.map((value, index) => (
                  <Draggable key={index} draggableId={`item-${index}`} index={index}>
                    {(provided) => (
                      <ListItem
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}

                        secondaryAction={
                          <IconButton aria-label="delete" onClick={() => handleDeleteValue(index)} title='Delete This'>
                            <DeleteIcon />
                          </IconButton>}
                      >
                        <ListItemAvatar>
                          < Avatar alt={+index + 1}>{+index + 1}</Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={value} />
                      </ListItem>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </List>
            )}
          </Droppable>
        </DragDropContext>
      </Box>
      <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
        <DialogTitle>Add New Value</DialogTitle>
        <DialogContent>
          <TextField
            label="New Value"
            value={newValue}
            onChange={(e) => setNewValue(e.target.value)}
            sx={{ mt: 1, mb: 1 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDialogOpen(false)} color='error'>Cancel</Button>
          <Button variant="outlined" onClick={handleAddValue}>Add</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ArrayData;