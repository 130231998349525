export const TELECONSULT_ROUTES = [{
    title: 'Choose Plans Screen', value: 'ChoosePlansScreen'
},
{
    title: 'My Plans Screen', value: 'MyPlansScreen',
},
{
    title: 'Moneyback Screen', value: 'MoneyBackHome',
},
{
    title: 'See how it works screen', value: 'SeeHowItWorks',
}]

export const HOME_ROUTES = [
    { title: 'My Helps Screen', value: 'myhelps' },
    { title: 'Verifications Screen', value: 'verifications' },
    { title: 'My Groups Screen', value: 'mygroups' },
    { title: 'User Profile Screen', value: 'userprofile' },
    { title: 'Chat Screen', value: 'chat' },
    { title: 'Location Screen', value: 'location' },
    { title: 'Attendance Screen', value: 'attendance' },
    { title: 'Notifications Screen', value: 'notifications' },
    { title: 'Expense Screen', value: 'expense' },
    { title: 'Sundry Screen', value: 'sundry' },
    { title: 'Salary Screen', value: 'salary' },
    { title: 'Loan Screen', value: 'loan' },
    { title: 'Teleconsultation Screen', value: 'teleconsultation' }
  ]