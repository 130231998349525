import userTypes from './user.types';

const INITIAL_STATE = {
  currentUser: null,
  userErr: [],
  preference: 'light',
  mainContent: { route: 'home', params: {} },
  strings: {},
  config: null
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case userTypes.SIGN_IN_SUCCESS:
      return {
        ...state,
        mainContent: { route: 'home', params: {} },
        currentUser: action.payload,
        userErr: []
      }
    case userTypes.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPasswordSuccess: action.payload
      }
    case userTypes.USER_ERROR:
      return {
        ...state,
        userErr: action.payload
      }
    case userTypes.SIGN_OUT_USER_SUCCESS:
      return {
        ...state,
        ...INITIAL_STATE,
        userErr: []
      }
    case userTypes.UPDATE_STRING_SUCCESS: 
      return {
        ...state, 
        strings: [...state.strings.filter(str => {return str.id !== action.payload.id}), 
          {...action.payload, [`${action.payload.language}`] : action.payload.value} ]
      }
    case userTypes.DELETE_STRING_SUCCESS: 
      const deletedValue = state.strings.filter(str => {return str.id === action.payload.id})
      return {
        ...state, 
        strings: [...state.strings.filter(str => {return str.id !== action.payload.id}), 
                  {...deletedValue[0], [`${action.payload.language}`] : ''} ]
    }
    case userTypes.FETCH_STRINGS_SUCCESS: 
      return {
        ...state,
        strings: [...action.payload.strings],
        userErr: []
      }
    case userTypes.FETCH_CONFIG_START: 
      return {
        ...state,
        config: {
          ...state.config,
          loading: true
        }
      }
    case userTypes.FETCH_CONFIG_SUCCESS: 
      return {
        ...state,
        config: action.payload,
        loading: false
      }
    default:
      return state
  }
};

export default userReducer;