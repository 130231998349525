import React from 'react'
import ArrayData from 'components/ChildComponents/ArrayData';

const HelperCategory = () => {
  return (
    <ArrayData 
      name='Helper Categories' 
      field={'helperCategories'}/>
  );
};

export default HelperCategory;