import { CssBaseline, Paper } from '@mui/material/';
import { ThemeProvider } from '@mui/material/styles';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Router } from 'react-router-dom';
import { checkUserModePreference } from './redux/user/user.actions';
import AOS from 'aos';
import { createBrowserHistory } from 'history';
import Routes from './Routes';

import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'aos/dist/aos.css';
import { createTheme } from '@mui/material';
import palette from './theme/palette';


const mapState = ({ user }) => ({
  preference: user.preference
})

const browserHistory = createBrowserHistory();

browserHistory.listen(location => {
  setTimeout(() => {
    if (location.action === 'POP') {
      return;
    }
    window.scrollTo(0, 0);
  });
});

function App() {
  AOS.init({
    once: false,
    mirror: true,
    delay: 50,
    duration: 500,
    easing: 'ease-in-out',
  });

  const { preference } = useSelector(mapState);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(checkUserModePreference());
  }, [])

  var nightModePreference;
  try {
    nightModePreference = typeof (preference.paletteMode) !== undefined ? preference.paletteMode : 'dark'
  } catch (error) {
    nightModePreference = 'dark'
  }

  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: '#3F35DA'
      }
    },
  });

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Paper elevation={0}>
        <Router history={browserHistory}>
            <Routes />
        </Router>
      </Paper>
    </ThemeProvider>
  );
}

export default App;
