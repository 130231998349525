import React from 'react'
import ArrayData from 'components/ChildComponents/ArrayData';

const AdminUsers = () => {
    return (
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            <ArrayData
                name='Admin Users Emails'
                field={'AdminUsersEmail'} />
            <ArrayData
                name='Admin Users Mobiles'
                field={'AdminUsersMobile'} />
        </div>
    );
};

export default AdminUsers;