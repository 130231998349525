import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import { useHistory } from 'react-router';
import { Typography, LinearProgress } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { fetchConfigStart, gapiSignInStart, signOutUserStart } from '../../../redux/user/user.actions';
import { firebaseExport as firebase, auth, firestore } from '../../../firebase/util';
import GoogleSigninImage from '../../../assets/google/btn_google_signin_dark_normal_web@2x.png'

const mapState = ({ user }) => ({
    mainContent: user.mainContent,
    currentUser: user.currentUser,
    config: user.config
});

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        display: 'flex',
    },
    formInputContentHolder: {
        marginTop: 25,
        marginBottom: 10
    },
    authInput: {
        marginTop: 15,
    },
    loginSubOptions: {
        marginTop: 25,
    },
    checkbox: {
        paddingLeft: 0,
    },
    authButton: {
        width: '-webkit-fill-available',
        textTransform: 'none',
        borderRadius: 12
    },
    heading: {
        marginTop: 5,
    },
    error: {
        color: '#ff4747'
    },
    containerForm: {
        maxWidth: '30vw',
        [theme.breakpoints.down('md')]: {
            maxWidth: '80vw',
        },
        padding: '3em',
        margin: '20vw auto',
        background: '#1e1e1e',
        textAlign: 'center',
        borderRadius: 20
    },
    addonGrid: {
        textAlign: 'center',
        alignItems: 'center',
        margin: 10
    }
}));


function Login() {
    const { currentUser, config } = useSelector(mapState)
    const dispatch = useDispatch()
    const classes = useStyles()
    const history = useHistory()
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)

    useEffect(() => {
        dispatch(fetchConfigStart())
    }, [])

    useEffect(() => {
      try {
        if(config){
            if(config.loading) setLoading(true)
            else setLoading(false)
          } else setLoading(false)
      } catch (error) {
        console.error(error)
        setLoading(false)
      }
    }, [config])
    


    const checkUser = async (email) => {
        try {
            if (config) if (config.AdminUsersEmail.includes(email)) return true
            else return false
        } catch (error) {
            console.error(error)
            return false
        }
    };

    const handleGoogleSignIn = async () => {
        try {
            const provider = new firebase.auth.GoogleAuthProvider();
            const result = await auth.signInWithPopup(provider);
            const email = result.user.email;
            const isValidatedUser = await checkUser(email);
            if (isValidatedUser) {
                dispatch(gapiSignInStart({ ...result }));
            } else {
                email.includes('@homdom.in') ? setError('Please refresh and relogin') : setError('Invalid Auth. Please contact Administrator');
                dispatch(signOutUserStart())
            }
        } catch (error) {
            console.error(error)
            setError(error.message);
        }
    };

    useEffect(() => {
        if (currentUser) {
            history.push('/admin');
        }
    }, [currentUser]);

    useEffect(() => {
        if (error) {
            setLoading(false);
        } else {
            setLoading(true);
        }
    }, [error]);

    return (
        <div className={classes.root}>
            {loading ? <LinearProgress /> : null}
            <Container className={classes.containerForm}>
                <Typography variant="h6" component="h6">
                    Welcome to
                </Typography>
                <Typography variant="h5" component="h6">
                    Homdom Admin Dashboard
                </Typography>
                <>
                    <div className={classes.formInputContentHolder}>
                        <img 
                            style={{pointerEvents: loading ? 'none' : 'auto'}}
                            src={GoogleSigninImage} 
                            className={classes.authButton} 
                            onClick={handleGoogleSignIn} 
                            title={loading ? 'Loading' : 'Click to Login'}
                            alt="Google SignIn" />
                    </div>
                    {error && <p className={classes.error}>{error}</p>}
                </>
            </Container>
        </div>
    );
}

export default Login;