export const serverConfig = {
    "type": "service_account",
    "project_id": "servito-homdom-server-main",
    "private_key_id": "130700906adee042d1a93ee7ac6513516b2723c9",
    "private_key": "-----BEGIN PRIVATE KEY-----\nMIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQDylklTCGPUQJoM\nzcNDFeSezOygrwTt4gtweprRxTZ9xUxcc5FZHOQI2mMXVJfsf2JfreD24PDdb/Cr\nbh0HDUaUa68dGHjSMESwT8Z0E+Jr5ovcNjoT3B9qbZYYufjyOpq5us0lID3Rq2FA\nobmP0VYndHEuPf0RFp0iSbdRTKyjcqFk38wK6LsGp5w3D53CmHT0+PS8YX+Chsab\nGP2VNva4xdPiDVh6VGyA7fytpBt1TofjRpbImrp+NgkR4a+oG4UArutL0ZiZ94YV\nn+o2GvsqnIbQRA08wkh8FlHE7+lA50jF7KDSTMfdEoOrNTvtEdzzrkYcGM9K528n\nO62ZQNPpAgMBAAECggEAFnbuYV8Uh5JX8bmYTH0Zh88oVpT+mtzk/EyOS8C8lBcS\nRDl7i0oOn/dBV+bAjukY/g1HGPWpuIfmPKnczEthbtsuAz7B6Y0Iv18T3Kn+vMlD\nyGfJJw+qZRuy26VVNXFR5MM0vixnLSR2xBp16aiUFuFEneilp9B1SriS3leAYT5N\nIo9v/i2MZLc/O2Mhr81d780QWPbpwy4YcxzD54JYqRc3Qk0hQNJ09BLTev6RwyDr\nP65i6DqKsC8V15XtwD6qWPaOsQ5bob7HQ7fl4FKb4cXsZ4aCwSXTcNFNUIjomklm\nYIS/13GT/UCTyAbStLM7xO7Na9v+DslAph3LvSS0kQKBgQD/Z+ORgLAPdjlj01Wb\n4gwSjIfPtTYSksxSLkEWAp84KNK5d+jI/loE205Sv+h3OtkGJ12+EGH1oITXXqqq\n0Atm/F4l6uSH7Tui6Ku18r++Sv20rhat6QCoWT9ps9bxP+ecrYKHijhMsnsaFilw\nKwEefAPl/I8vRhx4cYcBXIvudQKBgQDzJsNYNbV2G+XkQLNW6wHUNPAwdUCnhdD+\n0GzOCovtJ44wqih1wWvXMeMBICNKu1ZRlFvPCLo6T6JYWi3kvIsCZ1HXEaotpi2x\nYWD3Ifhl0NKnjajTd6uqkeoX8yOiiSkmouQoNKilkOZqx64/m9n4914DCu9/QMMO\nsg8zWOhJJQKBgHI2iAqaLKFNcg6VBBSIenOVCmq1E3As21fovUxVcdQQVWOBHsU7\nERBY7eaNUSx4UXKyCfajptrLpHwXJmTqUh8EvilgqD+ss91/BMKBwoMbT+cLbrLo\nnlWqS3Q+auFJfY6P/O4VvmV17xzUSkWRxZdXR/sB/Rnb1YF66y+Nrjm9AoGBAOOr\nQ/VwNLymWpdiTYnw+gFsoAl/gE5badlt5rL2BRbFlsnSSZgYx5TzTQ5cvS7YQnaY\nZ25vSZAXyHbZ2Wb3c2GIxISQMbwUgWw5NcWwtRkAcn4Ykh+PSdmoTnymWJNzwmg+\n1UsgI1kDV/xbg7hjgbEwTYKj1qRcS0eTSdBWSwlRAoGBANbgaOK69EnHIpNPbiG4\nwvvTT6/z1I0eObB96gHjlT34RTnedacsNH5Q7+Vue1QEVnKmMG/Nl9tjcC+PnFoM\nMcmNrJEyhxnCy9eDqD6OkIBg14FyBwcJr9xa4c5o9lyMTd0bfgy6BHsN90JzPR8J\n7frKlTN1UnfQ8PGPsSx4PmwT\n-----END PRIVATE KEY-----\n",
    "client_email": "image-upload-user@servito-homdom-server-main.iam.gserviceaccount.com",
    "client_id": "105831758161660675620",
    "auth_uri": "https://accounts.google.com/o/oauth2/auth",
    "token_uri": "https://oauth2.googleapis.com/token",
    "auth_provider_x509_cert_url": "https://www.googleapis.com/oauth2/v1/certs",
    "client_x509_cert_url": "https://www.googleapis.com/robot/v1/metadata/x509/image-upload-user%40servito-homdom-server-main.iam.gserviceaccount.com",
    "universe_domain": "googleapis.com"
  }