import { RouteWithLayout } from 'common';
import AdminPage from './pages/AdminPage/AdminPage';
import LoginPage from './pages/LoginPage/LoginPage';
import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import NormalHome from 'components/ParentComponents/NormalHome';
import AppStrings from 'components/ParentComponents/AppStrings';
import HelperCategory from 'components/ParentComponents/HelperCategory';
import HelperType from 'components/ParentComponents/HelperType';
import ConfigToggles from 'components/ParentComponents/ConfigToggles';
import WelcomeArtworks from 'components/ParentComponents/WelcomeArtworks';
import Banners from 'components/ParentComponents/Banners';
import InsuranceBanners from 'components/ParentComponents/InsuranceBanners';
import AdminUsers from 'components/ParentComponents/AdminUsers';
import ConsultationBanners from 'components/ParentComponents/ConsultationBanner';
import ConsultationVouchers from 'components/ParentComponents/ConsultationVouchers';
import HomeIcons from 'components/ParentComponents/HomeIcons';
import ConsultationPlans from 'components/ParentComponents/ConsultationPlan';
import SuccessfulSubscriptions from 'components/ParentComponents/Subscriptions';
import VoucherUsage from 'components/ParentComponents/VoucherAnalytics';
import PartnerAPIRequests from 'components/ParentComponents/PartnerApiRequests';

const Routes = () => {
  return (
    <Switch>
      <Redirect exact from="/" to="/login" />
      <Route exact path="/login" render={() => (
        <LoginPage />
      )} />
      <Route exact path="/admin" render={() => (
        <AdminPage component={<NormalHome />} />
      )} />
      <Route exact path="/admin/strings" render={() => (
        <AdminPage component={<AppStrings />} />
      )} />
      <Route exact path="/admin/helper-category" render={() => (
        <AdminPage component={<HelperCategory />} />
      )} />
      <Route exact path="/admin/helper-type" render={() => (
        <AdminPage component={<HelperType />} />
      )} />
      <Route exact path="/admin/config-toggles" render={() => (
        <AdminPage component={<ConfigToggles />} />
      )} />
      <Route exact path="/admin/welcome-artworks" render={() => (
        <AdminPage component={<WelcomeArtworks />} />
      )} />
      <Route exact path="/admin/banners" render={() => (
        <AdminPage component={<Banners />} />
      )} />
      <Route exact path="/admin/insurance-banner" render={() => (
        <AdminPage component={<InsuranceBanners />} />
      )} />
      <Route exact path="/admin/consultation-banner" render={() => (
        <AdminPage component={<ConsultationBanners />} />
      )} />
      <Route exact path="/admin/admin-users" render={() => (
        <AdminPage component={<AdminUsers />} />
      )} />
      <Route exact path="/admin/consultation-voucher" render={() => (
        <AdminPage component={<ConsultationVouchers />} />
      )} />
      <Route exact path="/admin/home-icons" render={() => (
        <AdminPage component={<HomeIcons />} />
      )} />
      <Route exact path="/admin/consultation-plan" render={() => (
        <AdminPage component={<ConsultationPlans />} />
      )} />
      <Route exact path="/admin/consultation-plan-subscribed" render={() => (
        <AdminPage component={<SuccessfulSubscriptions />} />
      )} />
      <Route exact path="/admin/consultation-voucher/analytics/:code" render={(props) => (
        <AdminPage component={<VoucherUsage  {...props} />} />
      )} />
      <Route exact path="/admin/partner-api-requests" render={() => (
        <AdminPage component={<PartnerAPIRequests />} />
      )} />
    </Switch>
  );
};

export default Routes;
