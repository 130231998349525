import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import Home from 'components/ParentComponents/Home';

const mapState = ({ user }) => ({
  mainContent: user.mainContent,
  currentUser: user.currentUser
})

function AdminPage({ component }) {
    const { mainContent, currentUser } = useSelector(mapState);
    const dispatch = useDispatch()
    const history = useHistory()

    useEffect(() => {
        if(!currentUser) history.push('/login')
    }, [currentUser])

    return (
      <Home
        mainContent={component}/>
    )
}

export default AdminPage