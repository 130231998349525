import React, { useEffect, useState } from 'react'
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarExport,
    GridToolbarDensitySelector,
} from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { serverUrl } from 'Constants';
import Typography from '@mui/material/Typography';
import { useLocation } from 'react-router-dom';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from 'recharts';

function PartnerAPIRequests({ gridName }) {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState([])
    const [data, setData] = useState([])
    const [panCount, setPanCount] = useState(0)
    const [voterIdCount, setVoterIdCount] = useState(0)
    const [aadharCount, setAadharCount] = useState(0)
    const [dLCount, setDLCount] = useState(0)
    const [upiCount, setUpiCount] = useState(0)
    const [submitOtpCount, setSubmitOtpCount] = useState(0)
    const [generateOtpCount, setGenerateOtpCount] = useState(0)

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }

    const columnsConfig = [
        { field: 'id', headerName: 'ID', editable: false, flex: true },
        { field: 'apiName', headerName: 'API Name', editable: false, flex: true },
        { field: 'calledAt', headerName: 'Called At', editable: false, flex: true },
        { field: 'source', headerName: 'Source', editable: false, flex: true },
        { field: 'env', headerName: 'ENV', editable: false, flex: true },
    ]

    const fetchDetails = () => {
        setLoading(true)
        fetch(`${serverUrl}/v1/verifications/fetchPartnerAPIRequests`)
            .then(response => response.json())
            .then(data => {
                if (data.status === 'OK') {
                    const getSourceCount = (source) => data.data.filter(item => item.source === source).length;

                    setPanCount(getSourceCount('panVerifications'));
                    setVoterIdCount(getSourceCount('voterIdVerifications'));
                    setAadharCount(getSourceCount('aadharVerifications'));
                    setDLCount(getSourceCount('dlVerifications'));
                    setUpiCount(getSourceCount('upiVerifications'));
                    setSubmitOtpCount(getSourceCount('submitAadharOTP'));
                    setGenerateOtpCount(getSourceCount('generateAadharOTP'));
                    setData(data.data)
                    setLoading(false)
                } else {
                    setError(data.message)
                    console.error(data)
                    setLoading(false)
                }
            })
            .catch(error => {
                console.error('Error:', error);
                setError(error.message)
                setLoading(false)
            });
    }

    useEffect(() => {
        setError('')
        fetchDetails()
    }, [])

    const rows = data.map((row) => ({
        id: row._id,
        apiName: row.apiName,
        calledAt: moment(row.calledAt).format('DD-MM-YYYY HH:mm:ss'),
        source: row.source,
        env: row.env,
    }));

    const pieDatas = [
        { name: 'Voter Id Verification', value: voterIdCount },
        { name: 'PAN Verification', value: panCount },
        { name: 'DL Verification', value: dLCount },
        { name: 'Aadhar Verification', value: aadharCount },
        { name: 'UPI Verification', value: upiCount },
        { name: 'Submit Aadhar Otp', value: submitOtpCount },
        { name: 'Generate Aadhar Otp', value: generateOtpCount },
    ];

    const COLORS = [
        '#FF6384',
        '#36A2EB',
        '#FFCE56',
        '#50E3C2',
        '#9966FF',
        '#FF9E64',
        '#FF6384',
    ];

    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
            const data = payload[0].payload;
            return (
                <div style={{ background: 'black', padding: '2px', border: '1px solid #ccc' }}>
                    <p>{data.name}, {data.value}</p>
                </div>
            );
        }
        return null;
    };

    return (
        <>
            <Typography variant="h5" gutterBottom>Partner API Analytics</Typography>
            <div style={{ height: '400', width: '100%' }}>
                <DataGrid
                    rows={rows}
                    columns={[
                        ...columnsConfig,
                    ]}
                    autoHeight={true}
                    pageSize={10}
                    autoPageSize={true}
                    loading={loading}
                    components={{
                        Toolbar: CustomToolbar,
                    }}
                    experimentalFeatures={{ newEditingApi: true }}
                    disableSelectionOnClick />
            </div>
            <ResponsiveContainer width="100%" height={400}>
                <PieChart>
                    <Pie
                        data={pieDatas}
                        cx="50%"
                        cy="50%"
                        labelLine={false}
                        outerRadius="80%"
                        fill="#8884d8"
                        dataKey="value"
                    >
                        {pieDatas.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                    </Pie>
                    <Tooltip content={<CustomTooltip />} />
                </PieChart>
            </ResponsiveContainer>
        </>
    )
}

export default PartnerAPIRequests
