import ArrayData from 'components/ChildComponents/ArrayData'
import React from 'react'
import { useSelector } from 'react-redux'

const mapState = ({ user }) => ({
  config: user.config,
})

function HelperType() {
  const { config } = useSelector(mapState)
  return (
    <ArrayData 
      name='Helper Types' 
      field={'helperCategoryType'}
      isObjectSpecific={true}
      objectName={'Helper Category'}
      objectArray={config ? config.helperCategories ? config.helperCategories : [] : []}/>
  )
}

export default HelperType