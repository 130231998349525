import { takeLatest, call, all, put } from 'redux-saga/effects';
import userTypes from './user.types';
import { mainContentSuccess, signInSuccess, userError, userModePreferenceSuccess, signOutUserStart, signOutUserSuccess, updateStringSuccess, deleteStringStart, deleteStringSuccess, fetchStringsStart, fetchStringsSuccess, fetchConfigSuccess, } from './user.actions';
import { gapiSignOut, handleDeleteString, handleDeleteString2, handleFetchConfig, handleFetchStrings, handleFetchStrings2, handleUpdateString, handleUpdateString2} from './user.helper';
import { handleUserProfile, auth } from '../../firebase/util';


export function* isNightModeUser() {
  try {
    let preference = localStorage.getItem('homdomPalette');
    if(preference !== 'dark' && preference !== 'light'){
      preference = 'light'
    }
    yield put(
      userModePreferenceSuccess({
        paletteMode: preference
      })
    );
  } catch (err) {
    console.error(err);
  }
}

export function* onCheckUserModePreference() {
  yield takeLatest(userTypes.CHECK_USER_MODE_PREFERENCE, isNightModeUser);
}

export function* setMainContent({ payload }) {
  try {
    let mainContent = payload;
    yield put(
      mainContentSuccess(mainContent)
    );
  } catch (err) {
    console.error('User Sagas', err);
  }
}

export function* onSetMainContentStart() {
  yield takeLatest(userTypes.SET_MAIN_CONTENT_START, setMainContent);
}

export function* getSnapshotFromUserAuth(user, additionalData = {}, isEnterpriseLogin) {
  try {
    //const userRef = yield call(handleUserProfile, { userAuth: user, additionalData, isEnterpriseLogin });
    if(user){
      //const snapshot = yield userRef.get();
      const { uid, photoURL, displayName, email } = user
      yield put(
        signInSuccess({
          uid, photoURL, displayName, email
        })
      );
      // yield generateRefreshToken(snapshot.id)
    } else {
      yield put(
        userError({error: '808: Invalid User', status: 808})
      );
      yield auth.signOut();
      yield gapiSignOut();
    }
    
  } catch (err) {
    console.error('error @ sagas login', err);
  }
}

export function* gapiSignIn({payload}) {
  try {
    const { user } = payload
    yield getSnapshotFromUserAuth(user, {} , true);
  } catch (err) {
    console.error(err);
    yield put(
      userError(err)
    );
  }
}

export function* onGapiSignInStart() {
  yield takeLatest(userTypes.GAPI_SIGN_IN_START, gapiSignIn);
}

export function* signOutUser() {
  try {
    yield auth.signOut();
    yield gapiSignOut();
    yield put(
      signOutUserSuccess()
    )
  } catch (err) {
    console.error(err);
  }
}

export function* onSignOutUserStart() {
  yield takeLatest(userTypes.SIGN_OUT_USER_START, signOutUser);
}

export function* onUpdateString({ payload }) {
  try {
    const result = yield handleUpdateString(payload)
    if(result.status === 200) yield put(updateStringSuccess(result.string))
    else yield put(userError(result.errors[0]))
  } catch (err) {
    console.error(err);
  }
}

export function* onUpdateStringStart() {
  yield takeLatest(userTypes.UPDATE_STRING_START, onUpdateString);
}

export function* onDeleteString({ payload }) {
  try {
    const result = yield handleDeleteString(payload)
    if(result.status === 200) yield put(deleteStringSuccess(result.string))
    else yield put(userError(result.errors[0]))
  } catch (err) {
    console.error(err);
  }
}

export function* onDeleteStringStart() {
  yield takeLatest(userTypes.DELETE_STRING_START, onDeleteString);
}

export function* onFetchStrings({ payload }) {
  try {
    const result = yield handleFetchStrings(payload)
    if(result.status === 200) yield put(fetchStringsSuccess(payload, result.strings))
    else yield put(userError(result.errors[0]))
  } catch (err) {
    console.error(err);
  }
}

export function* onFetchStringsStart() {
  yield takeLatest(userTypes.FETCH_STRINGS_START, onFetchStrings);
}

export function* onFetchConfig() {
  try {
    const config = yield handleFetchConfig()
    yield put(fetchConfigSuccess(config))
  } catch (err) {
    console.error(err);
  }
}

export function* onFetchConfigStart() {
  yield takeLatest(userTypes.FETCH_CONFIG_START, onFetchConfig);
}

export default function* userSagas() {
  yield all([
    call(onCheckUserModePreference),
    call(onSetMainContentStart),
    call(onGapiSignInStart),
    call(onSignOutUserStart),
    call(onUpdateStringStart),
    call(onDeleteStringStart),
    call(onFetchStringsStart),
    call(onFetchConfigStart)
  ])
}