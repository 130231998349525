import firebase from 'firebase/app';
import 'firebase/firebase'
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage'
import 'firebase/analytics'
import 'firebase/app-check'
import { firebaseConfig } from './config';

firebase.initializeApp(firebaseConfig);

export const analytics = firebase.analytics()
export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const storage = firebase.storage()
export const firebaseExport = firebase
export const GoogleProvider = new firebase.auth.GoogleAuthProvider();
export const appCheck = firebase.appCheck();
GoogleProvider.setCustomParameters({ promt: 'select_account'})

appCheck.activate(
    '6Lc7rN8gAAAAAPgBNzf1eKCnzyLS3BI8jihoqbJE',
    true);

export const getCurrentUser = () => {
    return new Promise((resolve, reject) => {
        const unsubscribe = auth.onAuthStateChanged(userAuth =>{
            unsubscribe();
            resolve(userAuth);
        }, reject);
    })
}

export const handleUserProfile = async ({ userAuth }) => {
    const { uid } = userAuth;
    return firestore.doc(`Users/${uid}`);
};