import { Button, FormControlLabel, FormGroup, FormLabel, Radio, RadioGroup, TextField, Typography, Tooltip } from '@mui/material'
import { serverUrl } from 'Constants'
import { DropzoneArea, DropzoneAreaBase } from 'material-ui-dropzone'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { customAlphabet } from 'nanoid';

const mapState = ({ user }) => ({
    currentUser: user.currentUser,
})

function EditConsultationVoucher({ voucher, postUpdate, setLoading, setError }) {
    const { currentUser } = useSelector(mapState)
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [code, setCode] = useState('')
    const [validity, setValidity] = useState('');
    const [date, setDate] = useState('')
    const [value, setValue] = useState('')
    const [isPercentage, setisPercentage] = useState(null)
    const [isPublic, setisPublic] = useState(null)
    const [errors, setErrors] = useState('');

    const blackListCharacters = ['1', 'I', 'L', 'O', 'i', 'l', 'o', '0'];

    const handleInputChange = (e) => {
        const inputValue = e.target.value;

        // Check if the input contains blacklisted characters
        const containsBlacklisted = blackListCharacters.some(char => inputValue.includes(char));

        if (containsBlacklisted) {
            setErrors(`This ${inputValue} Character Is not allowed.`);
        } else {
            setErrors('');
        }

        setCode(inputValue);
    };

    useEffect(() => {
        if (voucher) {
            setTitle(voucher.title)
            setDescription(voucher.description)
            setCode(voucher.code)
            setValidity(voucher.validity)
            const formattedDate = moment(voucher.validity, 'DD MMMM YYYY').format('YYYY-MM-DD');
            setDate(formattedDate);
            setValue(voucher.value)
            setisPercentage(voucher.isPercentage)
            setisPublic(voucher.isPublic)
        } else reset()
    }, [voucher])

    const handleDateChange = (event) => {
        const date = event.target.value;
        const formattedDate = moment(date).format('DD MMMM YYYY');
        setValidity(formattedDate);
        setDate(event.target.value)
    };

    const generateVoucherCode = () => {
        const alphaNumeric = 'ABCDEFGHJKMNPQRSTUVWXYZ23456789';
        const voucherCode = customAlphabet(alphaNumeric, 6);
        const code = 'HMDM' + voucherCode();
        setCode(code)
    };

    const handleSave = () => {
        setLoading(true)
        if (voucher) {
            console.log('voucher Edited')
            const config = {
                editBy: currentUser.email,
                operationType: 'edit',
                ...voucher,
                title,
                description,
                code,
                validity,
                value,
                isPercentage,
                isPublic
            }
            const options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ ...config }),
            };

            fetch(`${serverUrl}/v1/consultationVoucher/editConsultationVoucher`, options)
                .then(response => response.json())
                .then(data => {
                    console.log('Response:', data)
                    if (data.error) {
                        setError(data.error)
                        setLoading(false)
                    } else {
                        postUpdate()
                        reset()
                    }
                })
                .catch(error => {
                    console.error('Error:', error)
                    setError(error.message)
                    setLoading(false)
                })
        } else {
            console.log('New voucher Created')
            const config = {
                editBy: currentUser.email,
                operationType: 'create',
                title,
                description,
                code,
                validity,
                value,
                isPercentage,
                isPublic
            }
            const options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ ...config }),
            };

            fetch(`${serverUrl}/v1/consultationVoucher/editConsultationVoucher`, options)
                .then(response => response.json())
                .then(data => {
                    console.log('Response:', data)
                    if (data.error) {
                        setError(data.error)
                        setLoading(false)
                    } else {
                        postUpdate()
                        reset()
                    }
                })
                .catch(error => {
                    console.error('Error:', error)
                    setError(error.message)
                    setLoading(false)
                })
        }
    }

    const reset = () => {
        setError('')
        setLoading(false)
        setCode('')
        setTitle('')
        setDescription('')
        setDate('')
        setValue('')
        setisPercentage(null)
        setisPublic(null)
    }

    return (
        <div>
            <Typography variant="h6" gutterBottom>{voucher ? 'Edit Consultation Voucher' : 'Create Consultation Voucher'}</Typography>
            <TextField
                label="title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                sx={{ mt: 1, mb: 1 }}
            /> < br />
            <TextField
                label="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                sx={{ mt: 1, mb: 1 }}
            /> < br />
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <TextField
                    label="code"
                    value={code}
                    onChange={handleInputChange}
                    error={Boolean(errors)}
                    helperText={errors}
                    sx={{ mr: 1 }}
                />
                <Button variant='contained' onClick={() => generateVoucherCode()} sx={{ backgroundColor: '#4CAF50', color: 'white' }}>
                    Auto Generate Code
                </Button>
            </div>
            <TextField
                label="value"
                value={value}
                onChange={(e) => setValue(e.target.value)}
                sx={{ mt: 1, mb: 1 }}
            /> < br />
            <FormGroup>
                <FormLabel>Is Percentage</FormLabel>
                <RadioGroup
                    value={isPercentage}
                    onChange={(e) => setisPercentage(e.target.value)}
                    row
                >
                    <FormControlLabel
                        value="true"
                        control={<Radio />}
                        label="True" />
                    <FormControlLabel
                        value="false"
                        control={<Radio />}
                        label="False" />
                </RadioGroup>
            </FormGroup>
            <FormGroup>
                <FormLabel>Is Public</FormLabel>
                <RadioGroup
                    value={isPublic}
                    onChange={(e) => setisPublic(e.target.value)}
                    row
                >
                    <FormControlLabel
                        value="true"
                        control={<Radio />}
                        label="True" />
                    <FormControlLabel
                        value="false"
                        control={<Radio />}
                        label="False" />
                </RadioGroup>
            </FormGroup>
            <label htmlFor="datePicker">Validity: </label> < br />
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <input
                    type="date"
                    id="datePicker"
                    value={date}
                    onChange={handleDateChange}
                    style={{
                        width: '44%',
                        padding: '10px',
                        margin: '10px 0',
                        border: '1px solid #ccc',
                        borderRadius: '4px',
                        backgroundColor: '#333',
                        color: '#fff',
                    }}
                />
            </div>
            <Button
                variant='contained'
                onClick={() => handleSave()}
                disabled={voucher ? false : false}>
                {voucher ? 'Save Edit' : 'Create New'}
            </Button>
        </div>
    )
}

export default EditConsultationVoucher
