import { Button, FormControlLabel, FormGroup, FormLabel, Radio, RadioGroup, TextField, Tooltip, Typography } from '@mui/material'
import { serverUrl } from 'Constants'
import { DropzoneArea, DropzoneAreaBase } from 'material-ui-dropzone'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import FileUpload from 'utilities/FileUpload/FileUpload'
import FileUploadGCP from 'utilities/FileUploadGCP/FileUploadGCP'

const mapState = ({ user }) => ({
  currentUser: user.currentUser,
})

function EditArtwork({ artwork, postUpdate, setLoading, setError }) {
  const { currentUser } = useSelector(mapState)
  const [image, setImage] = useState(null)
  const [selectedFile, setSelectedFile] = useState(null)
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [type, setType] = useState(null)
  const [uploading, setUploading] = useState(false)

  useEffect(() => {
    setImage(null)
    setSelectedFile(null)
  }, [])


  useEffect(() => {
    if (artwork) {
      setTitle(artwork.title)
      setDescription(artwork.description)
      setType(artwork.type)
    } else reset()
  }, [artwork])

  const handleSave = () => {
    setLoading(true)
    if (artwork) {
      console.log('Artwork Edited')
      const config = {
        editBy: currentUser.email,
        operationType: 'edit',
        ...artwork,
        description,
        title,
        type
      }
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...config }),
      };

      fetch(`${serverUrl}/v1/home/editArtwork`, options)
        .then(response => response.json())
        .then(data => {
          console.log('Response:', data)
          if (data.error) {
            setError(data.error)
            setLoading(false)
          } else {
            postUpdate()
            reset()
          }
        })
        .catch(error => {
          console.error('Error:', error)
          setError(error.message)
          setLoading(false)
        })
    } else {
      console.log('New Artwork Created')
      const config = {
        editBy: currentUser.email,
        operationType: 'create',
        url: image,
        description,
        title,
        type
      }
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...config }),
      };

      fetch(`${serverUrl}/v1/home/editArtwork`, options)
        .then(response => response.json())
        .then(data => {
          console.log('Response:', data)
          if (data.error) {
            setError(data.error)
            setLoading(false)
          } else {
            postUpdate()
            reset()
          }
        })
        .catch(error => {
          console.error('Error:', error)
          setError(error.message)
          setLoading(false)
        })
    }
  }

  useEffect(() => {
    if(selectedFile) handleUpload()
  }, [selectedFile])

  const handleUpload = async () => {
    if (!selectedFile) return;
    setUploading(true)
    console.log({ selectedFile })
    let formData = new FormData();
    formData.append("file", selectedFile);
    const files = [{...selectedFile, formData}]
    const result = await FileUploadGCP(`${Date.now()}-${selectedFile.name}`, files)
    console.log({ result })
    if (result.status === 200) {
        setImage(result.fileUrls[0].url)
    }
    setUploading(false)
  };

  const reset = () => {
    document.getElementById('fileInput').value = ''
    setDescription('')
    setError('')
    setImage(null)
    setLoading(false)
    setSelectedFile(null)
    setTitle('')
    setType(null)
  }


  return (
    <div>
      <Typography variant="h6" gutterBottom>{artwork ? 'Edit Artwork' : 'Create Artwork'}</Typography>
      {!artwork ? <div>
        <input type="file" id='fileInput' onChange={e => setSelectedFile(e.target.files[0])} accept=".svg,.png"/><br />
        {selectedFile && !image ? uploading ? 'Uploading file...' : <Button onClick={() => handleUpload()}>Upload</Button> : image ? 'Upload Successful' : null}
        <br/>
      </div> : null}
      <TextField
        label="Title"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        sx={{ mt: 1, mb: 1 }}
      /> < br />
      <TextField
        label="Description"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        sx={{ mt: 1, mb: 1 }}
      />
      <FormGroup>
        <FormLabel>Type</FormLabel>
        <RadioGroup
          value={type}
          onChange={(e) => setType(e.target.value)}
          row
        >
          <FormControlLabel
            value="helper"
            control={<Radio />}
            label="Helper" />
          <FormControlLabel
            value="employer"
            control={<Radio />}
            label="Employer" />
        </RadioGroup>
      </FormGroup>
      <Tooltip title={artwork ? '' : image ? '' : 'Upload the selected image to the cloud first'}>
        <Button 
          variant='contained' 
          onClick={() => handleSave()} 
          disabled={artwork ? false : image ? false : true}>
          {artwork ? 'Save Edit' : 'Create New'}
      </Button>
      </Tooltip>
    </div>
  )
}

export default EditArtwork