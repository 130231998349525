import userTypes from './user.types';

export const emailSignInStart = userCredentials => ({
  type: userTypes.EMAIL_SIGN_IN_START,
  payload: userCredentials
});

export const signInSuccess = user => ({
  type: userTypes.SIGN_IN_SUCCESS,
  payload: user
});

export const checkUserSession = () => ({
  type: userTypes.CHECK_USER_SESSION
});

export const signOutUserStart = () => ({
  type: userTypes.SIGN_OUT_USER_START
});

export const signOutUserSuccess = () => ({
  type: userTypes.SIGN_OUT_USER_SUCCESS
});

export const signUpUserStart = userCredentials => ({
  type: userTypes.SIGN_UP_USER_START,
  payload: userCredentials
});

export const userError = err => ({
  type: userTypes.USER_ERROR,
  payload: err
});

export const resetPasswordStart = userCredentials => ({
  type: userTypes.RESET_PASSWORD_START,
  payload: userCredentials
});

export const resetPasswordSuccess = () => ({
  type: userTypes.RESET_PASSWORD_SUCCESS,
  payload: true
});

export const resetUserState = () => ({
  type: userTypes.RESET_USER_STATE
});

export const googleSignInStart = () => ({
  type: userTypes.GOOGLE_SIGN_IN_START
});

export const userModePreferenceSuccess = preference => ({
  type: userTypes.USER_MODE_PREFERENCE_SUCCESS,
  payload: preference
});

export const checkUserModePreference = () => ({
  type: userTypes.CHECK_USER_MODE_PREFERENCE
});

export const mainContentSuccess = mainContent => ({
  type: userTypes.MAIN_CONTENT_SUCCESS,
  payload: mainContent
});

export const setMainContentStart = mainContentName => ({
  type: userTypes.SET_MAIN_CONTENT_START,
  payload: mainContentName
});

export const gapiSignInStart = isEnterpriseLogin => ({
  type: userTypes.GAPI_SIGN_IN_START,
  payload: isEnterpriseLogin
});

export const updateStringStart = string => ({
  type: userTypes.UPDATE_STRING_START,
  payload: string
})

export const updateStringSuccess = response => ({
  type: userTypes.UPDATE_STRING_SUCCESS,
  payload: response
})

export const fetchStringsStart = language => ({
  type: userTypes.FETCH_STRINGS_START,
  payload: language
})

export const fetchStringsSuccess = (language, strings) => ({
  type: userTypes.FETCH_STRINGS_SUCCESS,
  payload: { language, strings }
})

export const deleteStringStart = string => ({
  type: userTypes.DELETE_STRING_START,
  payload: string
})

export const deleteStringSuccess = response => ({
  type: userTypes.DELETE_STRING_SUCCESS,
  payload: response
})


export const fetchConfigStart = () => ({
  type: userTypes.FETCH_CONFIG_START,
})

export const fetchConfigSuccess = config => ({
  type: userTypes.FETCH_CONFIG_SUCCESS,
  payload: config
})