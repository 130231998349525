import React, { useState, useEffect, useRef } from 'react'
import { RadioGroup, FormControlLabel, Radio, Button, Typography, FormLabel, FormGroup, LinearProgress, Switch, Box, TextField, Grid } from '@mui/material';
import BuildAndVersion from '../BuildAndVersion'
import { firestore } from 'firebase/util'
import { useDispatch, useSelector } from 'react-redux';
import { fetchConfigSuccess } from 'redux/user/user.actions';

const mapState = ({ user }) => ({
    config: user.config,
})

function UserTypeConfig({ userType, setLoading }) {
    const { config } = useSelector(mapState)
    const dispatch = useDispatch()
    const [authEngine, setAuthEngine] = useState('custom')
    const [chatEngine, setChatEngine] = useState('server')
    const [isCacheClear, setIsCacheClear] = useState(false)
    const [employerShare, setEmployerShare] = useState('')
    const [helperShare, setHelperShare] = useState('')

    useEffect(() => {
        try {
            setAuthEngine(config[userType].authEngine || 'custom')
            setChatEngine(config[userType].chatEngine || 'server')
            setIsCacheClear(config[userType].isCacheClear || false)
            setEmployerShare(config[userType].employerShare || '')
            setHelperShare(config[userType].helperShare || '')
        } catch (error) {
            setAuthEngine('custom')
            setChatEngine('server')
            setIsCacheClear(false)
            setEmployerShare(config[userType].employerShare || '')
            setHelperShare(config[userType].helperShare || '')
        }
    }, [config])

    const handleSave = async (field, value) => {
        try {
            setLoading(true)
            await firestore.collection('Config').doc(userType).set({ [field]: value }, { merge: true });
            dispatch(fetchConfigSuccess({...config, [userType]: {...config[userType], [field]: value}}))
            setLoading(false)
        } catch (error) {
            console.error('Error saving data:', error);
            setLoading(false)
        }
    };

    const handleChange = (value, setValue, field) => {
        try {
            setLoading(true)
            setValue(value)
            handleSave(field, value)
        } catch (error) {
            console.error(error)
            setLoading(false)
        }
    }

    const ShareInput = ({ label, value, field }) => {
        const [inputValue, setInputValue] = useState(value);
        const inputRef = useRef(null);

        useEffect(() => {
            if (inputRef.current) {
              inputRef.current.focus();
            }
          }, [inputValue]);
        

        return (
            <Grid container spacing={2} sx={{mb: 2}}>
                <Grid item xs={8}>
                    <TextField
                        fullWidth
                        multiline
                        minRows={4}
                        label={label}
                        id={`${field}-${userType}-text`}
                        value={inputValue}
                        inputRef={inputRef}
                        key={`${field}-${userType}-key`}
                        onChange={e => setInputValue(e.target.value)} />
                </Grid>
                <Grid item xs>
                    <Button
                        id={`${field}-${userType}-button`}
                        onClick={() => handleSave(field, inputValue)}>
                        Save
                    </Button>
                </Grid>
            </Grid>
        )
    }


    return (
        <>
            <Typography variant="h6" gutterBottom>
                {userType.charAt(0).toUpperCase() + userType.slice(1)}
            </Typography>
            <BuildAndVersion
                setLoading={setLoading}
                os='android'
                userType={userType} />
            <BuildAndVersion
                setLoading={setLoading}
                os='ios'
                userType={userType} />
            <FormGroup>
                <FormLabel>Auth Engine</FormLabel>
                <RadioGroup
                    value={authEngine}
                    onChange={(e) => handleChange(e.target.value, setAuthEngine, 'authEngine')}
                    row
                >
                    <FormControlLabel
                        value="custom"
                        control={<Radio />}
                        label="Custom" />
                    <FormControlLabel
                        value="firebase"
                        control={<Radio />}
                        label="Firebase" />
                </RadioGroup>

                <FormLabel>Chat Engine</FormLabel>
                <RadioGroup
                    value={chatEngine}
                    onChange={(e) => handleChange(e.target.value, setChatEngine, 'chatEngine')}
                    row
                >
                    <FormControlLabel
                        value="server"
                        control={<Radio />}
                        label="Server" />
                    <FormControlLabel
                        value="firebase"
                        control={<Radio />}
                        label="Firebase" />
                </RadioGroup>

                <FormLabel>isCacheClear</FormLabel>
                <FormControlLabel
                    checked={isCacheClear}
                    onChange={e => handleChange(e.target.checked, setIsCacheClear, 'isCacheClear')}
                    control={<Switch />}
                    label={isCacheClear ? "true" : "false"} />
                <br />
                <ShareInput 
                    label={'Employer Share'}
                    value={employerShare}
                    field={'employerShare'} />
                <ShareInput 
                    label={'Helper Share'}
                    value={helperShare}
                    field={'helperShare'} />
            </FormGroup>
        </>
    )
}

export default UserTypeConfig