import React, { useEffect, useState } from 'react'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import LaunchIcon from '@mui/icons-material/Launch';
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarExport,
    GridToolbarDensitySelector,
    GridRowModes,
    GridActionsCellItem
} from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import moment, { lang } from 'moment';
import GenerateUid from 'utilities/GenerateUid';
import { deleteStringStart, fetchStringsStart, updateStringStart } from 'redux/user/user.actions';
import { handleFetchAllStrings } from 'redux/user/user.helper';

function CustomToolbar(props) {
    const { setRows, setRowModesModel } = props;

    const handleClick = () => {
        const id = GenerateUid()
        setRows((oldRows) => [{ id, key: '', value: '', isNew: true }, ...oldRows, ]);
        setRowModesModel((oldModel) => ({
            [id]: { mode: GridRowModes.Edit, fieldToFocus: 'key' },
            ...oldModel
        }));
    };

    return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
            <GridToolbarExport />
            <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
                Add record
            </Button>
        </GridToolbarContainer>
    );
}

const languages = ['en', 'kn', 'hi']
const baseStrings = [{ key: 'appName', value: 'HomDom', id: 1, }]
const data = [...baseStrings]


const mapState = ({ user }) => ({
    strings: user.strings,
  })


function AppStringNew({ gridName }) {
    const { strings } = useSelector(mapState)
    const history = useHistory()
    const dispatch = useDispatch();
    const [language, setLanguage] = useState('en')
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState([])
    const [rowModesModel, setRowModesModel] = useState({})
    const [rows, setRows] = useState([])
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
    const [processId, setProcessId] = useState(null)

    const columnsConfig = [
        { field: 'key', headerName: 'String Key', editable: true, flex: true },
        { field: 'value', headerName: 'String Value', editable: true, flex: true },
    ]

    useEffect(() => {
      if(language) dispatch(fetchStringsStart(language))
    }, [language]) 

    useEffect(() => {
      try {
        if(language){
            if(strings) {
                if(Array.isArray(strings)) setRows(strings.map(str => {return {...str, value: str[`${language}`] ? str[`${language}`] : ''}}))
                else setRows([])
            } else setRows([])
          } else setRows([])
      } catch (error) {
        console.error(error)
        setRows([])
      }
    }, [strings, language]) 
    

    const handleCancelClick = (id) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });

        const editedRow = rows.find((row) => row.id === id);
        if (editedRow.isNew) {
            setRows(rows.filter((row) => row.id !== id));
        }
    };

    const handleRowEditStart = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    const handleRowEditStop = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    const handleEditClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const handleSaveClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleDeleteClick = (id) => () => {
        setProcessId(id)
        setShowDeleteConfirmation(true)
    };

    const processDeleteClick = () => {
        setShowDeleteConfirmation(false)
        if (processId) {
            dispatch(deleteStringStart({id: processId, language}))
            setRows(rows.filter((row) => row.id !== processId));
            setProcessId(null)
        } else console.error('Oops, processs id is null')
    }

    const processRowUpdate = (newRow, oldRow) => {
        const updatedRow = { ...newRow, isNew: false };
        let editedValues = {}
        for (let prop in newRow) {
            if (oldRow[prop] !== newRow[prop]) editedValues[prop] = newRow[prop]

        }
        setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
        if(newRow.isNew) {
            console.log('Save the new String', {...newRow, language})
            dispatch(updateStringStart({...newRow, language}))
        } else {
            dispatch(updateStringStart({...newRow, language}))
            console.log('Update the existing String', {...newRow, language})
        }
        return updatedRow;
    };

    const columns = [
        ...columnsConfig,
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            cellClassName: 'actions',
            getActions: (props) => {
                const { id, row } = props
                const isInEditMode = rowModesModel[id] ? rowModesModel[id].mode === "edit" : false;
                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon />}
                            label="Save"
                            onClick={handleSaveClick(id, row)}
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon />}
                            label="Cancel"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Edit"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        onClick={handleDeleteClick(id)}
                        color="inherit"
                    />,
                ]
            },
        }

    ]

    const handleDeleteDialogClose = () => {
        setProcessId(null)
        setShowDeleteConfirmation(false)
    }

    return (
        <>
            <FormControl fullWidth>
                <InputLabel id="lang-select-label">Language</InputLabel>
                <Select
                    labelId="lang-select-label"
                    id="lang-select"
                    value={language}
                    label="Age"
                    onChange={e => setLanguage(e.target.value)}
                >
                    <MenuItem value={''} key={0} disabled>Select a Language</MenuItem>
                    {languages.map(lang => {
                        return <MenuItem value={lang} key={lang}>{lang}</MenuItem>
                    })}
                </Select>
            </FormControl>
            <DataGrid
                rows={rows}
                columns={columns}
                editMode="row"
                autoHeight={true}
                pageSize={10}
                autoPageSize={true}
                loading={loading}
                rowModesModel={rowModesModel}
                onRowEditStart={handleRowEditStart}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                components={{
                    Toolbar: CustomToolbar,
                }}
                componentsProps={{
                    toolbar: { setRows, setRowModesModel },
                }}
                experimentalFeatures={{ newEditingApi: true }}
                disableSelectionOnClick />
            <Dialog
                open={showDeleteConfirmation}
                onClose={handleDeleteDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Are you sure to delete?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Once you delete, the data is lost and can't be retrived.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteDialogClose}>Disagree</Button>
                    <Button onClick={processDeleteClick} autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default AppStringNew