import React, { useState } from 'react'
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { Cancel, DeleteSharp, Edit, OpenInNew } from '@mui/icons-material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import EditConsultationVoucher from '../EditConsultationVouchers';
import { useEffect } from 'react';
import { Chip, Grid, LinearProgress } from '@mui/material';
import { useSelector } from 'react-redux';
import { serverUrl } from 'Constants';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import Alert from '@mui/material/Alert';
import AnalyticsIcon from '@mui/icons-material/BarChart';
import { useHistory } from 'react-router-dom';

const mapState = ({ user }) => ({
    currentUser: user.currentUser,
})

function ConsultationVouchers() {
    const history = useHistory()
    const { currentUser } = useSelector(mapState)
    const [vouchers, setvouchers] = useState([])
    const [open, setOpen] = useState(false)
    const [action, setAction] = useState(null)
    const [edit, setEdit] = useState(false)
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setError('')
        fetchVouchers()
    }, [])

    const handleAnalyticsView = (code) => {
        const url = `/admin/consultation-voucher/analytics/${code}`;
        window.open(url, '_blank');
        // history.push(`/admin/consultation-voucher/analytics/${code}`);
    };

    const fetchVouchers = () => {
        setLoading(true)
        fetch(`${serverUrl}/v1/consultationVoucher/fetchConsultationVoucher`)
            .then(response => response.json())
            .then(data => {
                if (data.status === 'OK') {
                    setvouchers(data.data.vouchers)
                    setLoading(false)
                } else {
                    setError(data.message)
                    console.error(data)
                    setLoading(false)
                }
            })
            .catch(error => {
                console.error('Error:', error);
                setError(error.message)
                setLoading(false)
            });
    }

    const handleClose = () => {
        setOpen(false)
        setAction(null)
        setError('')
    }

    const handleDelete = () => {
        setOpen(false)
        setLoading(true)
        setAction(null)
        const config = {
            editBy: currentUser.email,
            operationType: 'delete',
            _id: action._id
        }

        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ ...config }),
        };

        fetch(`${serverUrl}/v1/consultationVoucher/editConsultationVoucher`, options)
            .then(response => response.json())
            .then(data => {
                setError('')
                console.log('Response:', data);
                setvouchers(vouchers.filter(artwork => { return artwork._id !== action._id }))
            })
            .catch(error => {
                console.error('Error:', error);
                setError(error.message)
            })
        setLoading(false)
    }

    const handleEditView = banner => {
        setError('')
        setEdit(true)
        setAction(banner)
    }

    const removeEditMode = () => {
        setError('')
        setEdit(false)
        setAction(null)
    }

    const handlePostUpdate = () => {
        setError('')
        setEdit(false)
        setAction(null)
        setLoading(false)
        fetchVouchers()
    }

    const handleAlertClose = () => {
        setError(null);
    };

    return (
        <>
            {loading ? <LinearProgress /> : null}
            <div>
                <Typography variant="h5" gutterBottom>Consultation Vouchers</Typography>
                <div>
                    {error && (
                        <Alert severity="error" onClose={handleAlertClose}>
                            {error}
                        </Alert>
                    )}
                </div>
                <Grid container spacing={2} sx={{ mt: 2 }}>
                    <Grid item xs={6}>
                        {vouchers.map((voucher, index) => {
                            //const url = banner.imageUrl
                            return <div key={voucher._id} id={voucher._id} style={{ marginBottom: '16px' }}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography variant="h6" component="div">
                                            Title: {voucher.title}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            Description: {voucher.description}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            Code: {voucher.code}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            Validity: {voucher.validity}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            Value: {voucher.value}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            Is Percentage: {voucher.isPercentage ? "True" : "False"}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            Is Public: {voucher.isPublic ? "True" : "False"}
                                        </Typography>
                                    </CardContent>
                                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '16px', marginBottom: '16px' }}>
                                        <IconButton
                                            aria-label="analytics"
                                            onClick={() => handleAnalyticsView(voucher.code)}
                                        >
                                            <AnalyticsIcon />
                                        </IconButton>
                                        <IconButton
                                            aria-label="edit"
                                            onClick={() => (edit && action._id === voucher._id ? removeEditMode() : handleEditView(voucher))}
                                        >
                                            {edit && action._id === voucher._id ? <CancelIcon /> : <EditIcon />}
                                        </IconButton>
                                    </div>
                                </Card>
                            </div>
                        })}
                    </Grid>
                    <Grid item xs={6}>
                        {edit ? <EditConsultationVoucher
                            setError={setError}
                            voucher={action}
                            postUpdate={handlePostUpdate}
                            setLoading={setLoading} /> :
                            <EditConsultationVoucher
                                setError={setError}
                                postUpdate={handlePostUpdate}
                                setLoading={setLoading} />}
                    </Grid>
                </Grid>
            </div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Are you sure you want to delete?"}
                </DialogTitle>
                <DialogContent>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={() => handleDelete()} autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default ConsultationVouchers