const userTypes = {
    EMAIL_SIGN_IN_START: 'EMAIL_SIGN_IN_START',
    SIGN_IN_SUCCESS: 'SIGN_IN_SUCCESS',
    CHECK_USER_SESSION: 'CHECK_USER_SESSION',
    SIGN_OUT_USER_START: 'SIGN_OUT_USER_START',
    SIGN_OUT_USER_SUCCESS: 'SIGN_OUT_USER_SUCCESS',
    SIGN_UP_USER_START: 'SIGN_UP_USER_START',
    RESET_PASSWORD_START: 'RESET_PASSWORD_START',
    RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
    GOOGLE_SIGN_IN_START: 'GOOGLE_SIGN_IN_START',
    USER_ERROR: 'USER_ERROR',
    RESET_USER_STATE: 'RESET_USER_STATE',
    USER_MODE_PREFERENCE_SUCCESS: 'USER_MODE_PREFERENCE_SUCCESS',
    CHECK_USER_MODE_PREFERENCE: 'CHECK_USER_MODE_PREFERENCE',
    MAIN_CONTENT_SUCCESS: 'MAIN_CONTENT_SUCCESS',
    SET_MAIN_CONTENT_START: 'SET_MAIN_CONTENT_START',
    GAPI_SIGN_IN_START: 'GAPI_SIGN_IN_START',
    SERVER_OPERATION_PENDING: 'SERVER_OPERATION_PENDING',
    UPDATE_STRING_START: 'UPDATE_STRING_START',
    UPDATE_STRING_SUCCESS: 'UPDATE_STRING_SUCCESS',
    FETCH_STRINGS_START: 'FETCH_STRINGS_START',
    FETCH_STRINGS_SUCCESS: 'FETCH_STRINGS_SUCCESS',
    DELETE_STRING_START: 'DELETE_STRING_START',
    DELETE_STRING_SUCCESS: 'DELETE_STRING_SUCCESS',
    FETCH_CONFIG_START: 'FETCH_CONFIG_START',
    FETCH_CONFIG_SUCCESS: 'FETCH_CONFIG_SUCCESS'
  };
  
  export default userTypes;