import { firestore, auth, getCurrentUser, storage } from "../../firebase/util";

export const gapiSignOut = () => {
  console.log('Signing Out!')
  return new Promise(async (resolve, reject) => {
    await auth.signOut()
    resolve()
  })
}

export const handleUpdateString1 = async (string) => {
  console.log('handleUpdateString', string)
  let status = 404
  let errors = []
  try { 
    const { id, language, key, value } = string
    if(!key || !language) return { status, errors: ['Fields are missing'], string}
    const ref = firestore.collection(`Strings-${language}`).doc(id)
    await ref.set({key, value})
    status = 200
  } catch(error){
    console.error(error)
    errors.push(error.message)
  }
  return { status, errors, string }
}

export const handleDeleteString1 = async (string) => {
  console.log('handleDeleteString', string)
  let status = 404
  let errors = []
  try { 
    const { id, language } = string
    if(!language || !id) return { status, errors: ['Fields are missing'], string}
    const ref = firestore.collection(`Strings-${language}`).doc(id)
    await ref.delete()
    status = 200
  } catch(error){
    console.error(error)
    errors.push(error.message)
  }
  return { status, errors, string }
}

export const handleFetchStrings1 = async (language) => {
  console.log('handleFetchStrings', language)
  let status = 404
  let errors = []
  let strings = []
  try { 
    if(!language) return { status, errors: ['language is missing']}
    const collection = await firestore.collection(`Strings-${language}`).get()

    collection.forEach((doc) => {
      strings.push({...doc.data(), id: doc.id});
    });

    status = 200
  } catch(error){
    console.error(error)
    errors.push(error.message)
  }
  return { status, errors, strings }
}


export const handleUpdateString = async (string) => {
  console.log('handleUpdateString', string)
  let status = 404
  let errors = []
  try { 
    const { id, language, key, value } = string
    if(!key || !language) return { status, errors: ['Fields are missing'], string}
    const ref = firestore.collection(`Strings`).doc(key.trim())
    await ref.set({[`${language}`]: value.trim(), id}, {merge: true})
    status = 200
  } catch(error){
    console.error(error)
    errors.push(error.message)
  }
  return { status, errors, string }
}

export const handleDeleteString = async (string) => {
  console.log('handleDeleteString', string)
  let status = 404
  let errors = []
  try { 
    const { id, language, } = string
    if(!language || !id) return { status, errors: ['Fields are missing'], string}
    const querySnapshot = await firestore.collection(`Strings`).where("id", "==", id).limit(1).get()
    querySnapshot.forEach(async (doc) => {
      await doc.ref.update({ [`${language}`]: ''});
    });
    status = 200
  } catch(error){
    console.error(error)
    errors.push(error.message)
  }
  return { status, errors, string }
}

export const handleFetchStrings = async (language) => {
  console.log('handleFetchStrings', language)
  let status = 404
  let errors = []
  let strings = []
  try { 
    if(!language) return { status, errors: ['language is missing']}
    const collection = await firestore.collection(`Strings`).get()

    collection.forEach((doc) => {
      let data = {...doc.data()}
      let key = doc.id
      strings.push({...data, key});
    });

    status = 200
  } catch(error){
    console.error(error)
    errors.push(error.message)
  }
  return { status, errors, strings }
}


export const handleFetchConfig = async () => {
  let config = null
  try { 
    const document = await firestore.collection(`Config`).doc('Uhb9yf38ykZEsAXAsNRo').get()
    const helper = (await firestore.collection('Config').doc('helper').get()).data()
    const employer = (await firestore.collection('Config').doc('employer').get()).data()
    config = {...document.data(), employer, helper}
  } catch(error){
    console.error(error)
  }
  return config
}


export const handleFetchAllStrings = async () => {
  let strings = null
  try { 
    const config = await handleFetchConfig()
    strings = config.languages.reduce((acc, language) => {
      acc[language] = {};
      return acc;
    }, {});
    const collection = await firestore.collection(`Strings`).get()
    collection.forEach((doc) => {
      let data = {...doc.data()}
      let key = doc.id
      Object.keys(strings).forEach(lang => {
        strings[`${lang}`][`${key}`] = data[`${lang}`] || ''
      })
    });
  } catch(error){
    console.error(error)
  }
  return strings
}



