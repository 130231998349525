import React, { useEffect, useState } from 'react'
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarExport,
    GridToolbarDensitySelector,
} from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { serverUrl } from 'Constants';
import Typography from '@mui/material/Typography';
import { useLocation } from 'react-router-dom';

function VoucherUsage({ gridName }) {
    const location = useLocation();
    const voucherCode = location.pathname.split('/').pop();
    const history = useHistory()
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState([])
    const [voucher, setVoucher] = useState([])

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }

    const columnsConfig = [
        { field: 'userId', headerName: 'uid', editable: false, flex: true },
        { field: 'id', headerName: 'Subscription Id', editable: false, flex: true },
        { field: 'boughtBy', headerName: 'Name', editable: false, flex: true },
        { field: 'boughtAt', headerName: 'Used On', editable: false, flex: true },
        { field: 'actualPrice', headerName: 'Actual Price', editable: false, flex: true },
        { field: 'finalPrice', headerName: 'Final Price', editable: false, flex: true },
    ]

    const fetchVoucherDetails = () => {
        setLoading(true)
        fetch(`${serverUrl}/v1/consultationVoucher/voucherUsage/${voucherCode}`)
            .then(response => response.json())
            .then(data => {
                if (data.status === 'OK') {
                    const filteredPlans = data.data.filter(item => item.boughtBy !== null);
                    setVoucher(filteredPlans)
                    setLoading(false)
                } else {
                    setError(data.message)
                    console.error(data)
                    setLoading(false)
                }
            })
            .catch(error => {
                console.error('Error:', error);
                setError(error.message)
                setLoading(false)
            });
    }

    useEffect(() => {
        setError('')
        fetchVoucherDetails()
    }, [])

    const rows = voucher.map((row) => ({
        userId: row.boughtBy._id,
        id: row._id,
        boughtBy: row.boughtBy.firstName ? row.boughtBy.firstName + " " + row.boughtBy.lastName : row.boughtBy.mobileNumber,
        boughtAt: moment(row.boughtAt).format('DD-MM-YYYY'),
        actualPrice: row.actualPrice,
        finalPrice: row.finalPrice,
    }));

    return (
        <>
            <Typography variant="h5" gutterBottom>Voucher Analytics for {voucherCode}</Typography>
            <div style={{ height: '400', width: '100%' }}>
                <DataGrid
                    rows={rows}
                    columns={[
                        ...columnsConfig,
                    ]}
                    autoHeight={true}
                    pageSize={10}
                    autoPageSize={true}
                    loading={loading}
                    components={{
                        Toolbar: CustomToolbar,
                    }}
                    experimentalFeatures={{ newEditingApi: true }}
                    disableSelectionOnClick />
            </div>
        </>
    )
}

export default VoucherUsage
