import { Button, FormControlLabel, FormGroup, FormLabel, Radio, RadioGroup, TextField, Typography, Tooltip, Autocomplete } from '@mui/material'
import { serverUrl } from 'Constants'
import { HOME_ROUTES } from 'constants/OnPressRoutes'
import { DropzoneArea, DropzoneAreaBase } from 'material-ui-dropzone'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import FileUpload from 'utilities/FileUpload/FileUpload'
import FileUploadGCP from 'utilities/FileUploadGCP/FileUploadGCP'

const mapState = ({ user }) => ({
    currentUser: user.currentUser,
})

function EditBanner({ banner, postUpdate, setLoading, setError }) {
    const { currentUser } = useSelector(mapState)
    const [image, setImage] = useState(null)
    const [selectedFile, setSelectedFile] = useState(null)
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [bannerType, setBannerType] = useState(null)
    const [bannerFor, setBannerFor] = useState(null)
    const [bannerCategory, setBannerCategory] = useState(null)
    const [onPress, setOnPress] = useState('')
    const [uploading, setUploading] = useState(false)

    useEffect(() => {
        setImage(null)
        setSelectedFile(null)
    }, [])


    useEffect(() => {
        if (banner) {
            setBannerCategory(banner.bannerCategory)
            setBannerFor(banner.bannerFor)
            setBannerType(banner.type)
            setOnPress(banner.onPress)
        } else reset()
    }, [banner])

    const handleSave = () => {
        setLoading(true)
        if (banner) {
            console.log('banner Edited')
            const config = {
                editBy: currentUser.email,
                operationType: 'edit',
                ...banner,
                bannerCategory,
                bannerFor,
                bannerType,
                onPress
            }
            const options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ ...config }),
            };

            fetch(`${serverUrl}/v1/home/editbanner`, options)
                .then(response => response.json())
                .then(data => {
                    console.log('Response:', data)
                    if (data.error) {
                        setError(data.error)
                        setLoading(false)
                    } else {
                        postUpdate()
                        reset()
                    }
                })
                .catch(error => {
                    console.error('Error:', error)
                    setError(error.message)
                    setLoading(false)
                })
        } else {
            console.log('New banner Created')
            const config = {
                editBy: currentUser.email,
                operationType: 'create',
                imageUrl: image,
                bannerCategory,
                bannerFor,
                bannerType,
                onPress,
                secondaryText: 'secondaryText', 
                primaryText: 'primaryText'
            }
            const options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ ...config }),
            };

            fetch(`${serverUrl}/v1/home/editbanner`, options)
                .then(response => response.json())
                .then(data => {
                    console.log('Response:', data)
                    if (data.error) {
                        setError(data.error)
                        setLoading(false)
                    } else {
                        postUpdate()
                        reset()
                    }
                })
                .catch(error => {
                    console.error('Error:', error)
                    setError(error.message)
                    setLoading(false)
                })
        }
    }

    useEffect(() => {
        if(selectedFile) handleUpload()
      }, [selectedFile])

    const handleUpload = async () => {
        if (!selectedFile) return;
        setUploading(true)
        console.log({ selectedFile })
        let formData = new FormData();
        formData.append("file", selectedFile);
        const files = [{...selectedFile, formData}]
        const result = await FileUploadGCP(`${Date.now()}-${selectedFile.name}`, files)
        console.log({ result })
        if (result.status === 200) {
            setImage(result.fileUrls[0].url)
        }
        setUploading(false)
    };

    const reset = () => {
        document.getElementById('fileInput').value = ''
        setError('')
        setImage(null)
        setLoading(false)
        setSelectedFile(null)
        setBannerType(null)
        setBannerCategory(null)
        setBannerFor(null)
        setOnPress('')
    }


    return (
        <div>
            <Typography variant="h6" gutterBottom>{banner ? 'Edit Banner' : 'Create Banner'}</Typography>
            {!banner ? <div>
                <input type="file" id='fileInput' onChange={e => setSelectedFile(e.target.files[0])} accept=".svg,.png"/><br />
                {selectedFile && !image ? uploading ? 'Uploading file...' : <Button onClick={() => handleUpload()}>Upload</Button> : image ? 'Upload Successful' : null}
                <br />
            </div> : null}
            <FormGroup>
                <FormLabel>Banner Type</FormLabel>
                <RadioGroup
                    value={bannerType}
                    onChange={(e) => setBannerType(e.target.value)}
                    row
                >
                    <FormControlLabel
                        value="header"
                        control={<Radio />}
                        label="Header" />
                    <FormControlLabel
                        value="footer"
                        control={<Radio />}
                        label="Footer" />
                </RadioGroup>
            </FormGroup>
            <FormGroup>
                <FormLabel>Banner Category</FormLabel>
                <RadioGroup
                    value={bannerCategory}
                    onChange={(e) => setBannerCategory(e.target.value)}
                    row
                >
                    <FormControlLabel
                        value="SHOW_ALL"
                        control={<Radio />}
                        label="SHOW_ALL" />
                    <FormControlLabel
                        value="NO_HELPER"
                        control={<Radio />}
                        label="NO_HELPER" />
                </RadioGroup>
            </FormGroup>
            <FormGroup>
                <FormLabel>Banner For</FormLabel>
                <RadioGroup
                    value={bannerFor}
                    onChange={(e) => setBannerFor(e.target.value)}
                    row
                >
                    <FormControlLabel
                        value="helper"
                        control={<Radio />}
                        label="Helper" />
                    <FormControlLabel
                        value="employer"
                        control={<Radio />}
                        label="Employer" />
                </RadioGroup>
            </FormGroup>
            <Autocomplete
                disablePortal
                id="onpress-auto"
                options={HOME_ROUTES}
                getOptionLabel={option => option.title}
                onChange={(e, value) => setOnPress(value ? value.value : '')}
                sx={{ width: 200 }}
                renderInput={(params) => <TextField {...params} label="onPress" />}
                />
            <Tooltip title={banner ? '' : image ? '' : 'Upload the selected image to the cloud first'}>
            <Button 
                variant='contained' 
                onClick={() => handleSave()}
                disabled={banner ? false : image ? false : true}>
                {banner ? 'Save Edit' : 'Create New'}
            </Button>
            </Tooltip>
        </div>
    )
}

export default EditBanner