import React, { useState } from 'react'
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Cancel, DeleteSharp, Edit, OpenInNew } from '@mui/icons-material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { ReactSVG } from "react-svg";
import EditArtwork from '../EditArtwork';
import { useEffect } from 'react';
import { Chip, Grid, LinearProgress } from '@mui/material';
import { useSelector } from 'react-redux';
import { serverUrl } from 'Constants';

const mapState = ({ user }) => ({
    currentUser: user.currentUser,
  })

function WelcomeArtworks() {
    const { currentUser } = useSelector(mapState)
    const [artworks, setArtworks] = useState([])
    const [open, setOpen] = useState(false)
    const [action, setAction] = useState(null)
    const [edit, setEdit] = useState(false)
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setError('')
        fetchArtworks()
    }, [])


    const fetchArtworks = () => {
        setLoading(true)
        fetch(`${serverUrl}/v1/home/fetchWelcomeArtworks?userType=all`)
            .then(response => response.json())
            .then(data => {
                if (data.status === 'OK') {
                    setArtworks(data.data)
                    setLoading(false)
                } else {
                    setError(data.message)
                    console.error(data)
                    setLoading(false)
                }
            })
            .catch(error => {
                console.error('Error:', error);
                setError(error.message)
                setLoading(false)
            });
    }

    const handleDeleteConfimation = artwork => {
        setOpen(true)
        setAction(artwork)
        setError('')
    }

    const handleClose = () => {
        setOpen(false)
        setAction(null)
        setError('')
    }

    const handleDelete = () => {
        setOpen(false)
        setLoading(true)
        setAction(null)
        const config = {
            editBy: currentUser.email,
            operationType: 'delete',
            _id: action._id
        }

        const options = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({...config}),
          };

        fetch(`${serverUrl}/v1/home/editArtwork`, options)
            .then(response => response.json())
            .then(data => {
                setError('')
                console.log('Response:', data);
                setArtworks(artworks.filter(artwork => { return artwork._id !== action._id }))
            })
            .catch(error => {
                console.error('Error:', error);
                setError(error.message)
            })
        setLoading(false)
    }

    const handleEditView = artwork => {
        setError('')
        setEdit(true)
        setAction(artwork)
    }

    const removeEditMode = () => {
        setError('')
        setEdit(false)
        setAction(null)
    }

    const handlePostUpdate = (isEdit) => {
        setError('')
        setEdit(false)
        setAction(null)
        setLoading(false)
        fetchArtworks()
    }

    return (
        <>
            {loading ? <LinearProgress /> : null}
            <div>
                <Typography variant="h5" gutterBottom>Welcome Artworks</Typography>
                {error !== '' ? <p>{error}</p> : null}
                <Grid container spacing={2} sx={{ mt: 2 }}>
                    <Grid item xs={6}>
                        {artworks.map((artwork, index) => {
                            const url = artwork.url
                            return <div key={artwork._id} id={artwork._id}>
                                <Card sx={{ m: 1 }}>
                                    <CardMedia
                                        component="img"
                                        height="194"
                                        style={{width: 'auto', margin: '0 auto'}}
                                        image={url}
                                        key={`key-svg-${artwork._id}`}
                                        id={`id-svg-${artwork._id}`}
                                        alt={`artwork ${index}`}
                                    />
                                    <CardContent>
                                        <Typography variant="body1" color="text.secondary">
                                            {artwork.title}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            {artwork.description}
                                        </Typography>
                                        <Chip label={artwork.type} />
                                    </CardContent>
                                    <CardActions disableSpacing>
                                        <IconButton aria-label="open image" onClick={() => window.open(url, '_blank')} title='open image'>
                                            <OpenInNew />
                                        </IconButton>
                                        <IconButton aria-label="delete" onClick={() => handleDeleteConfimation(artwork)} title='delete'>
                                            <DeleteSharp />
                                        </IconButton>
                                        <IconButton aria-label="edit" onClick={() => edit && action._id === artwork._id ? removeEditMode () : handleEditView(artwork)} title='edit'>
                                            {edit && action._id === artwork._id ? <Cancel /> : <Edit />}
                                        </IconButton>
                                    </CardActions>
                                </Card>
                            </div>
                        })}
                    </Grid>
                    <Grid item xs={6}>
                        {edit ? <EditArtwork 
                                    setError={setError}
                                    artwork={action} 
                                    postUpdate={handlePostUpdate} 
                                    setLoading={setLoading}/> : 
                                <EditArtwork 
                                    setError={setError}
                                    postUpdate={handlePostUpdate} 
                                    setLoading={setLoading}/>}
                    </Grid>
                </Grid>
            </div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Are you sure you want to delete?"}
                </DialogTitle>
                <DialogContent>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={() => handleDelete()} autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default WelcomeArtworks