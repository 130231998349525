import React, { useState } from 'react'
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { Cancel, DeleteSharp, Edit, OpenInNew, PlayLesson } from '@mui/icons-material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import EditConsultationPlan from '../EditConsultationPlan';
import { useEffect } from 'react';
import { Chip, Grid, LinearProgress } from '@mui/material';
import { useSelector } from 'react-redux';
import { serverUrl } from 'Constants';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';

const mapState = ({ user }) => ({
    currentUser: user.currentUser,
})

function ConsultationPlans() {
    const { currentUser } = useSelector(mapState)
    const [plans, setPlans] = useState([])
    const [open, setOpen] = useState(false)
    const [action, setAction] = useState(null)
    const [edit, setEdit] = useState(false)
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setError('')
        fetchPlans()
    }, [])


    const fetchPlans = () => {
        setLoading(true)
        fetch(`${serverUrl}/v1/consultation/fetchAllConsultationPlans`)
            .then(response => response.json())
            .then(data => {
                if (data.status === 'OK') {
                    setPlans(data.data.plans)
                    setLoading(false)
                } else {
                    setError(data.message)
                    console.error(data)
                    setLoading(false)
                }
            })
            .catch(error => {
                console.error('Error:', error);
                setError(error.message)
                setLoading(false)
            });
    }

    const handleClose = () => {
        setOpen(false)
        setAction(null)
        setError('')
    }

    const handleDelete = () => {
        setOpen(false)
        setLoading(true)
        setAction(null)
        const config = {
            editBy: currentUser.email,
            operationType: 'delete',
            _id: action._id
        }

        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ ...config }),
        };

        fetch(`${serverUrl}/v1/consultation/editConsultationPlan`, options)
            .then(response => response.json())
            .then(data => {
                setError('')
                console.log('Response:', data);
                setPlans(plans.filter(plan => { return plan._id !== action._id }))
            })
            .catch(error => {
                console.error('Error:', error);
                setError(error.message)
            })
        setLoading(false)
    }

    const handleEditView = plan => {
        setError('')
        setEdit(true)
        setAction(plan)
    }

    const removeEditMode = () => {
        setError('')
        setEdit(false)
        setAction(null)
    }

    const handlePostUpdate = () => {
        setError('')
        setEdit(false)
        setAction(null)
        setLoading(false)
        fetchPlans()
    }

    return (
        <>
            {loading ? <LinearProgress /> : null}
            <div>
                <Typography variant="h5" gutterBottom>Consultation Plans</Typography>
                {error !== '' ? <p>{error}</p> : null}
                <Grid container spacing={2} sx={{ mt: 2 }}>
                    <Grid item xs={6}>
                        {plans.map((plan, index) => {
                            //const url = banner.imageUrl
                            return <div key={plan._id} id={plan._id} style={{ marginBottom: '16px' }}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography variant="h6" component="div">
                                            Title: {plan.title}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            Sub-Title: {plan.subtitle}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            Price: {plan.price}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            Ready To Sale: {plan.readyToSale ? "True" : "False"}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            Is Recommended: {plan.isRecommended ? "True" : "False"}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            Expiry (Number Of Days): {plan.expiry}
                                        </Typography>
                                        <Typography variant="" color="textSecondary">
                                            Features:
                                        </Typography>
                                        <ul>
                                            {plan.features.map((feature, index) => (
                                                <li key={index}>
                                                    {feature}
                                                </li>
                                            ))}
                                        </ul>
                                    </CardContent>
                                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '16px', marginBottom: '16px' }}>
                                        <IconButton
                                            aria-label="edit"
                                            onClick={() => (edit && action._id === plan._id ? removeEditMode() : handleEditView(plan))}
                                        >
                                            {edit && action._id === plan._id ? <CancelIcon /> : <EditIcon />}
                                        </IconButton>
                                    </div>
                                </Card>
                            </div>
                        })}
                    </Grid>
                    <Grid item xs={6}>
                        {edit ? <EditConsultationPlan
                            setError={setError}
                            plan={action}
                            postUpdate={handlePostUpdate}
                            setLoading={setLoading} /> :
                            <EditConsultationPlan
                                setError={setError}
                                postUpdate={handlePostUpdate}
                                setLoading={setLoading} />}
                    </Grid>
                </Grid>
            </div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Are you sure you want to delete?"}
                </DialogTitle>
                <DialogContent>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={() => handleDelete()} autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default ConsultationPlans