import React, { useEffect, useState } from 'react'
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarExport,
    GridToolbarDensitySelector,
} from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { serverUrl } from 'Constants';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import { Chip } from '@mui/material';

function SuccessfulSubscriptions({ gridName }) {
    const history = useHistory()
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState([])
    const [plans, setPlans] = useState([])

    const handleVoucherCodeClick = (code) => {
        const url = `/admin/consultation-voucher/analytics/${code}`;
        window.open(url, '_blank');
        //  history.push(`/admin/consultation-voucher/analytics/${code}`);
    };

    const PlanDetailsCell = ({ value }) => (
        <Tooltip title={getFullDetails(value)} arrow>
            <Typography variant="subtitle2">Hover to view</Typography>
        </Tooltip>
    );

    const getFullDetails = (planDetails) => (
        <div>
            <div>
                <Typography variant="subtitle1">Title: {planDetails.title}</Typography>
                <Typography variant="body2">Sub Title: {planDetails.subtitle}</Typography>
                <Typography variant="body2">Price: {planDetails.price}</Typography>
                <Typography variant="body2">Created Date: {moment(planDetails.createdAt).format('DD-MM-YYYY')}</Typography>
                <Typography variant="body2">Updated Date: {moment(planDetails.updatedAt).format('DD-MM-YYYY')}</Typography>
                <Typography variant="body2">Ready To Sale: {planDetails.readyToSale ? 'True' : 'False'}</Typography>
                <Typography variant="body2">Is Recommended: {planDetails.isRecommended ? 'True' : 'False'}</Typography>
                <Typography variant="body2">expiry: {planDetails.expiry} days</Typography>
                <Typography variant="body2">Features:</Typography>
                <ul>
                    {planDetails.features.map((feature, index) => (
                        <li key={index}>{feature}</li>
                    ))}
                </ul>
            </div>
        </div>
    );

    const BeneficiaryDetailsCell = ({ value }) => {
        try {
            if (value.length) {
                return (
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {value.map((beneficiary, index) => {
                            return <Tooltip title={generateBeneficiaryDetail(beneficiary)} arrow key={index}>
                                <Chip
                                    key={index}
                                    variant="outlined"
                                    size="small"
                                    label={beneficiary.firstName ?
                                        `${beneficiary.firstName} ${beneficiary.lastName}` :
                                        beneficiary.mobileNumber} />
                            </Tooltip>
                        })}
                    </div>
                );
            } else {
                return (
                    <div>
                        <Typography variant="subtitle1">Not Assigned</Typography>
                    </div>
                );
            }
        } catch (error) {
            return <div>
                <Typography variant="subtitle1">Something went wrong!</Typography>
            </div>
        }
    };

    const generateBeneficiaryDetail = (detail) => {
        try {
            return <div>
                <Typography variant="subtitle1">Name: {detail.firstName ? detail.firstName + ' ' + detail.lastName : detail.mobileNumber}</Typography>
                <Typography variant="body2">Mobile Number: {detail.mobileNumber}</Typography>
            </div>
        } catch (error) {
            return <div>
                <Typography variant="subtitle1">Something went wrong!</Typography>
            </div>
        }
    }

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }

    const columnsConfig = [
        { field: 'id', headerName: 'Subscription Id', editable: false, flex: true },
        { field: 'boughtAt', headerName: 'Bought At', editable: false, flex: true },
        { field: 'boughtBy', headerName: 'Bought By', editable: false, flex: true },
        { field: 'mobileNumber', headerName: 'Mobile Number', editable: false, flex: true },
        { field: 'startDate', headerName: 'Start Date', editable: false, flex: true },
        { field: 'endDate', headerName: 'End Date', editable: false, flex: true },
        {
            field: 'voucherCode', headerName: 'Used Voucher Code', editable: false, flex: true,
            renderCell: (params) => (
                <div>
                    <Tooltip title={`Click to view analytics for ${params.value}`} arrow>
                        <Typography
                            variant="body2"
                            style={{ cursor: 'pointer', color: 'blue' }}
                            onClick={() => handleVoucherCodeClick(params.value)}
                        >
                            {params.value}
                        </Typography>
                    </Tooltip>
                </div>
            ),
        },
        {
            field: 'mbVoucher', headerName: 'Generated Voucher Code', editable: false, flex: true,
            renderCell: (params) => (
                <div>
                    <Tooltip title={`Click to view analytics for ${params.value}`} arrow>
                        <Typography
                            variant="body2"
                            style={{ cursor: 'pointer', color: 'blue' }}
                            onClick={() => handleVoucherCodeClick(params.value)}
                        >
                            {params.value}
                        </Typography>
                    </Tooltip>
                </div>
            ),
        },
        { field: 'actualPrice', headerName: 'Actual Price', editable: false, flex: true },
        { field: 'finalPrice', headerName: 'Final Price', editable: false, flex: true },
        {
            field: 'beneficiary', headerName: 'Beneficiary', editable: false, flex: true,
            renderCell: (params) => <BeneficiaryDetailsCell value={params.value} />
        },
        {
            field: 'planDetails', headerName: 'Plan Details', editable: false, flex: true,
            renderCell: (params) => <PlanDetailsCell value={params.value} />
        },
    ]

    const fetchPlans = () => {
        setLoading(true)
        fetch(`${serverUrl}/v1/consultation/listSuccessfulSubscriptions`)
            .then(response => response.json())
            .then(data => {
                if (data.status === 'OK') {
                    const filteredPlans = data.data.filter(item => item.boughtBy !== null);
                    setPlans(filteredPlans)
                    setLoading(false)
                } else {
                    setError(data.message)
                    console.error(data)
                    setLoading(false)
                }
            })
            .catch(error => {
                console.error('Error:', error);
                setError(error.message)
                setLoading(false)
            });
    }

    useEffect(() => {
        setError('')
        fetchPlans()
    }, [])

    const rows = plans.map((row) => ({
        id: row._id,
        boughtAt: moment(row.createdAt).format('DD-MM-YYYY'),
        startDate: row.startDate ? moment(row.startDate).format('DD-MM-YYYY') : "",
        endDate: row.endDate ? moment(row.endDate).format('DD-MM-YYYY') : "",
        voucherCode: row.couponCode ? row.couponCode : "",
        mbVoucher: row.mbVoucher ? row.mbVoucher.code : "",
        boughtBy: row.boughtBy.firstName ? row.boughtBy.firstName + " " + row.boughtBy.lastName : row.boughtBy.mobileNumber,
        mobileNumber: row.boughtBy.mobileNumber,
        actualPrice: row.actualPrice,
        finalPrice: row.finalPrice,
        planDetails: row.planDetails,
        beneficiary: row.beneficiary,
    }));

    return (
        <>
            <Typography variant="h5" gutterBottom>Subscriptions</Typography>
            <div style={{ height: '400', width: '100%' }}>
                <DataGrid
                    rows={rows}
                    columns={[
                        ...columnsConfig,
                    ]}
                    autoHeight={true}
                    pageSize={10}
                    autoPageSize={true}
                    loading={loading}
                    experimentalFeatures={{ newEditingApi: true }}
                    components={{
                        Toolbar: CustomToolbar,
                    }}
                    getRowHeight={({ id, densityFactor, model }) => {
                        if (model.beneficiary) {
                            try {
                                return model.beneficiary.length > 2 ? (Math.round(model.beneficiary.length / 2)) * 50 * densityFactor : null
                            } catch (error) {
                                return null
                            }
                        } else return null;
                    }}
                    disableSelectionOnClick />
            </div>
        </>
    )
}

export default SuccessfulSubscriptions
